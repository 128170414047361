<template>
  <div>
    <b-modal
      ref="bill-warranty-modal"
      hide-footer
      :titleHtml="modalTitle"
      size="xl"
    >
      <v-form ref="form">
        <b-row class="mb-4">
          <b-col md="4">
            <b-input
              size="sm"
              placeholder="Tìm kiếm"
              v-model="searchText"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <b-table :items="listItem" :fields="fields" bordered hover show-empty>
          <template #empty="scope">
            <h5 class="text-center">Không có GBH bán kèm nào được gợi ý</h5>
          </template>
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="isCheckAll"
                size="lg"
                @change="clickAll"
              ></b-form-checkbox>
            </span>
          </template>
          <!-- check bõ -->
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.checked"
                @change="clickIndex()"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(id)="row">
            <div>
              <span
                v-text="row.item.id"
                class="d-flex justify-content-center"
              ></span>
            </div>
          </template>
        </b-table>
        <b-button
          style="fontweight: 600; width: 100px"
          variant="primary"
          size="sm"
          @click="submit"
          >Áp dụng</b-button
        >
        <b-button
          style="margin-left: 10px; font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
        >
          Hủy
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { convertPrice, xoa_dau } from '../../../utils/common';
import { cloneDeep } from 'lodash';

export default {
  props: ['productName', 'storeId', 'productId'],
  data() {
    return {
      modalTitle: '',
      searchText: '',
      listItem: [],
      listItemDraff: [],
      fields: [
        {
          key: 'selected',
          label: '',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', textAlign: 'center' },
        },
        {
          key: 'productCode',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'productName',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
      ],
      isCheckAll: false,
    };
  },
  methods: {
    async fetchProductWarranty() {
      ApiService.get(`productSearch/warranty-product/${this.productId}`).then(
        ({ data }) => {
          const listData = data.data;
          this.listItem = listData.map((element) => {
            return {
              ...element,
              checked: false,
            };
          });
          this.listItemDraff = cloneDeep(this.listItem);
        },
      );
    },
    showModal: async function () {
      this.searchText = '';
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Thêm GBH bán kèm cho sản phẩm: <a class='text-primary'>#${this.productName}</a></span>
              </div>`;
      await this.fetchProductWarranty();
      this.$refs['bill-warranty-modal'].show();
    },
    hideModal: function () {
      this.$refs['bill-warranty-modal'].hide();
    },
    clickAll() {
      this.listItem.forEach((element) => {
        element.checked = this.isCheckAll;
      });
    },
    clickIndex() {
      let check = true;
      for (let index = 0; index < this.listItem.length; index++) {
        const element = this.listItem[index];
        if (element.checked === false) {
          check = false;
          break;
        }
      }
      this.isCheckAll = check;
    },
    onFilter() {
      const textSearch = this.searchText ? this.searchText.trim() : '';
      this.listItem = this.listItemDraff
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.productName);
          const codeNoneSign = xoa_dau(item.productCode);
          return (
            nameNoneSign.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
            item.productName.toLowerCase().indexOf(textSearch.toLowerCase()) >
              -1 ||
            codeNoneSign.toLowerCase().indexOf(textSearch.toLowerCase()) > -1 ||
            item.productCode.toLowerCase().indexOf(textSearch.toLowerCase()) >
              -1
          );
        })
        .slice(0, 10);
    },
    submit() {
      const listResult = this.listItem.filter((item) => item.checked);
      this.$emit('addWarranty', listResult);
      this.hideModal();
    },
  },
};
</script>