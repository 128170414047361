<template>
  <div>
    <b-modal ref="list-imei-modal" hide-footer :titleHtml="modalTitle">
      <v-form ref="form" lazy-validation>
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="3"></vcl-table>
          </template>
          <template v-slot:cell(imeiCode)="row">
            <div class="d-flex justify-content-center">
              <span v-text="row.item.imeiCode"></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <v-icon
                small
                class="text-danger"
                @click="applyImei(row.item.imeiCode)"
                v-b-tooltip
                title="Áp dụng"
                >mdi-pen</v-icon
              >
            </div>
          </template>
        </b-table>
        <b-button
          style="font-weight: 600; width: 70px"
          variant="secondary"
          size="sm"
          @click="hideModal"
          >Hủy</b-button
        >
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { VclTable } from 'vue-content-loading';
import { convertPrice } from '../../../utils/common';
export default {
  props: ['billItemId', 'productName', 'storeId'],
  data() {
    return {
      modalTitle: '',
      searchText: '',
      listItem: [],
      fields: [
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '60%',
          },
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '30%',
          },
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        { key: 'actions', label: '' },
      ],
      onLoading: false,
    };
  },
  components: {
    VclTable,
  },
  watch: {},
  methods: {
    convertPrice,
    fetchData(productId) {
      this.onLoading = true;
      const param = {
        productId: productId,
        storeId: this.storeId,
      };
      const paramQuery = {
        params: param,
      };
      ApiService.query('imeis/get-by-product', paramQuery)
        .then(({ data }) => {
          this.listItem = data.data.map((element) => {
            return {
              id: element.id,
              imeiCode: element.imeiNo,
              originalPrice: element.originalPrice ? element.originalPrice : 0,
              sellingPrice: element.sellingPrice ? element.sellingPrice : 0,
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    showModal: function (productId) {
      this.fetchData(productId);
      this.modalTitle = `<div>
              <span class='d-block' style='font-size: 1.1rem'>Danh sách IMEI sản phẩm: <a class='text-primary'>#${this.productName}</a></span>
              </div>`;
      this.$refs['list-imei-modal'].show();
    },
    hideModal: function () {
      this.$refs['list-imei-modal'].hide();
    },
    applyImei(imeiCode) {
      this.$emit('applyImei', imeiCode);
      this.hideModal();
    },
  },
};
</script>