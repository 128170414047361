<template>
  <div>
    <b-modal
      ref="add-note-modal"
      hide-footer
      :title="'Thêm ghi chú cho sản phẩm ' + productName"
    >
      <b-row class="mb-5">
        <b-col>
          <label>Ghi chú</label>
          <b-form-textarea
            v-model="noteBillItem"
            placeholder="Nhập ghi chú..."
            rows="5"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="onSubmit"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModal"
            >Hủy</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ['note', 'productName'],
  data() {
    return {
      noteBillItem: '',
    };
  },
  methods: {
    showModal: function () {
      this.noteBillItem = this.note;
      this.$refs['add-note-modal'].show();
    },
    hideModal: function () {
      this.$refs['add-note-modal'].hide();
    },
    onSubmit() {
      this.$emit('addNote', this.noteBillItem);
      this.hideModal();
    },
  },
};
</script>