<template>
  <div>
    <b-row style="margin-left: -25px">
      <b-col md="4">
        <b-form-group :disabled="disabled">
          <label>Ghi chú bán hàng:</label>
          <b-form-textarea
            size="sm"
            class="input-style"
            v-model="billNote.customerNote"
            :placeholder="'Thêm ghi chú'"
            :rows="6"
            :max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :disabled="disabled">
          <label>Ghi chú nội bộ:</label>
          <b-form-textarea
            size="sm"
            class="input-style"
            v-model="billNote.saleNote"
            :placeholder="'Thêm ghi chú nội bộ'"
            :rows="6"
            :max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group :disabled="disabled">
          <label>Ghi chú bảo hành:</label>
          <b-form-textarea
            size="sm"
            class="input-style"
            v-model="billNote.warrantyNote"
            :placeholder="'Thêm ghi chú bảo hành'"
            :rows="6"
            :max-rows="6"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ['billNote', 'disabled'],
};
</script>
