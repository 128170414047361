<template>
  <div>
    <b-modal ref="modal-upsert-shiftWork" id="modal-upsert-shiftWork" size="lg" :title="title" @hidden="resetModal"
      hide-footer>
      <v-form ref="form" v-model="valid" lazy-validation>
        <b-container fluid>
          <b-row class="mb-3">
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Cửa hàng: " :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="$v.form.selectedStore.$model" :suggestions="filteredStoreOptions"
                  :placeholder="'cửa hàng'" :limit="100" @select="onSelected('Store', $event)"
                  @change="onInputStoreChange" :state="validateState('storeId')" errorMessages="Yêu cầu chọn cửa hàng"
                  suggestionName="name" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Ca làm việc:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg"
                :disabled="this.disableInput">
                <Autosuggest :model="$v.form.selectedShiftWork.$model" :suggestions="filteredShiftWorkOptions"
                  :placeholder="'ca làm việc'" :disabled="this.disableInput" :limit="100"
                  @select="onSelected('ShiftWork', $event)" :state="validateState('shiftWorkId')"
                  errorMessages="Yêu cầu chọn ca làm việc" suggestionName="suggestionName" />

              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Ngày áp dụng:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <date-picker placeholder="Nhập ngày áp dụng" class="form-control form-control-sm date-picker"
                  :config="dpConfigs.date" v-model="form.applyDate"></date-picker>
                <p v-if="isDifferentApplyDate" class="text-warning mb-0">Ngày áp dụng sẽ không phải ngày hiện tại !</p>
                <b-form-invalid-feedback :state="validateState('applyDate')">Yêu cầu chọn ngày áp
                  dụng</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Quản lý của hàng:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedManager" :suggestions="filteredManagerOptions"
                  :placeholder="'quản lý của hàng'" :limit="100" @select="onSelected('Manager', $event)"
                  @change="onInputManagerChange" suggestionName="suggestionName" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Trợ lý của hàng:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedAssistant" :suggestions="filteredAssistantOptions"
                  :placeholder="'trợ lý cửa hàng'" :limit="100" @select="onSelected('Assistant', $event)"
                  @change="onInputAssistantChange" suggestionName="suggestionName" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Nhân viên kỹ thuật:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedTechnical" :suggestions="filteredEmployeeOptions"
                  :placeholder="'nhân viên kỹ thuật'" :limit="100" @select="onSelected('Technical', $event)"
                  @change="onInputTechnicalChange" suggestionName="suggestionName" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Nhân viên CDPK:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedAccessoryStaff" :suggestions="filteredEmployeeOptions"
                  :placeholder="'nhân viên CDPK'" :limit="100" @select="onSelected('AccessoryStaff', $event)"
                  @change="onInputAccessoryStaffChange" suggestionName="suggestionName" />
                <span v-if="showErrorStore" class="text-danger">
                  Vui lòng chọn cửa hàng
                </span>
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Nhân viên thu ngân:" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedCashier" :suggestions="filteredEmployeeOptions"
                  :placeholder="'nhân viên thu ngân'" :limit="100" @select="onSelected('Cashier', $event)"
                  @change="onInputCashierChange" suggestionName="suggestionName" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Tiếp đón khách hàng" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedCustomerReception" :suggestions="filteredEmployeeOptions"
                  :placeholder="'nhân viên tiếp đón khách hàng'" :limit="100"
                  @select="onSelected('CustomerReception', $event)" @change="onInputCustomerReceotionChange"
                  suggestionName="suggestionName" />
              </b-form-group>
            </b-col>
            <b-col md="6" class="px-3 pb-3">
              <b-form-group label="Nhân viên giao hàng" :label-class="['text-dark', 'font-weight-bold']" label-size="lg">
                <Autosuggest :model="selectedShipper" :suggestions="filteredEmployeeOptions"
                  :placeholder="'nhân viên giao hàng'" :limit="100" @select="onSelected('Shipper', $event)"
                  @change="onInputShipperChange" suggestionName="suggestionName" />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <div class="d-flex justify-content-end">
            <b-button class="mr-3" @click="hideModal" variant="danger">Hủy</b-button>
            <b-button variant="success" @click="submit">Cập nhập</b-button>
          </div>
        </b-container>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import ApiService from '@/core/services/api.service';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { removeAccents } from '@/utils/common';
import { JOB_TITLE } from '@/utils/enum';
import datePicker from 'vue-bootstrap-datetimepicker';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatDate } from '@/utils/date';
import moment from 'moment';
import { MESSAGE_ERROR } from '@/utils/constants';

export default {
  data() {
    return {
      title: 'Cấu hình ca làm việc',
      valid: true,
      filteredStoreOptions: [],
      storeOptions: [],
      filteredShiftWorkOptions: [],
      shiftWorkOptions: [],
      form: {
        selectedStore: '',
        storeId: null,
        selectedShiftWork: '',
        shiftWorkId: null,
        applyDate: null,
      },
      disableInput: true,
      showErrorStore: false,
      showErrorShiftWorkId: false,
      employeesOptions: [],
      filteredEmployeeOptions: [],

      managerOptions: [],
      filteredManagerOptions: [],
      selectedManager: '',
      managerId: null,

      assistantOptions: [],
      filteredAssistantOptions: [],
      selectedAssistant: '',
      assistantId: null,

      selectedTechnical: '',
      technicalId: null,

      selectedAccessoryStaff: '',
      accessoryStaffId: null,

      selectedCustomerReception: '',
      customerReceptionId: null,

      selectedCashier: '',
      cashierId: null,

      selectedShipper: '',
      shipperId: null,
      typeAction: '',
      ACTION: {
        UPDATE: 1,
        CREATE: 2,
        UPSERT: 3,
      },
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
    };
  },
  created() {
    this.fetchShiftWork();
  },
  mounted() {
    this.fetchStoreByUser();
  },
  computed: {
    isDifferentApplyDate() {
      const currentDate = moment().startOf('day')
      const applyDate = moment(this.form.applyDate, 'DD/MM/YYYY').startOf('day')
      const diffInDays = applyDate.diff(currentDate, 'days')
      return diffInDays > 0;
    }
  },
  watch: {
    shiftWorkConfig(newShiftWorkConfig) {
      this.form = {
        selectedShiftWork: newShiftWorkConfig.name,
        shiftWorkId: newShiftWorkConfig.shiftWorkId,
        selectedStore: newShiftWorkConfig.storeName,
        storeId: newShiftWorkConfig.storeId,
        applyDate: formatDate(newShiftWorkConfig.applyDate, 'DD/MM/YYYY'),
      }
      this.selectedManager = newShiftWorkConfig.managerName;
      this.selectedAssistant = newShiftWorkConfig.assistantName;
      this.selectedTechnical = newShiftWorkConfig.technicalName;
      this.selectedAccessoryStaff = newShiftWorkConfig.accessoryStaffName;
      this.selectedCashier = newShiftWorkConfig.cashierName;
      this.selectedCustomerReception = newShiftWorkConfig.customerReceptionName;
      this.selectedShipper = newShiftWorkConfig.shipperName;
      this.managerId = newShiftWorkConfig.managerId;
      this.assistantId = newShiftWorkConfig.assistantId;
      this.technicalId = newShiftWorkConfig.technicalId;
      this.accessoryStaffId = newShiftWorkConfig.accessoryStaffId;
      this.cashierId = newShiftWorkConfig.cashierId;
      this.customerReceptionId = newShiftWorkConfig.customerReceptionId;
      this.shipperId = newShiftWorkConfig.shipperId;
      this.getListEmployeeByStore();
      this.disableInput = !!this.form.shiftWorkId;
    },
    action(newTypeAction) {
      this.typeAction = newTypeAction;
      if (this.typeAction === this.ACTION.CREATE) {
        this.title = 'Thêm cấu hình ca làm việc';
        this.disableInput = false;
      } else {
        this.title = `Cấu hình ${this.shiftWorkConfig.name}`;
      }
    },
  },
  props: ['shiftWorkConfig', 'storeId', 'storeName', 'action'],
  methods: {
    getData() {
      return {
        id: this.shiftWorkConfig ? this.shiftWorkConfig.id : null,
        storeId: this.$v.form.storeId.$model,
        shiftWorkId: this.$v.form.shiftWorkId.$model,
        technicalName: this.selectedTechnical,
        technicalId: this.technicalId,
        cashierName: this.selectedCashier,
        cashierId: this.cashierId,
        managerName: this.selectedManager,
        managerId: this.managerId,
        assistantName: this.selectedAssistant,
        assistantId: this.assistantId,
        accessoryStaffName: this.selectedAccessoryStaff,
        accessoryStaffId: this.accessoryStaffId,
        customerReceptionName: this.selectedCustomerReception,
        customerReceptionId: this.customerReceptionId,
        shipperName: this.selectedShipper,
        shipperId: this.shipperId,
        applyDate: this.form.applyDate
          ? moment(this.form.applyDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      };
    },
    fetchShiftWork: async function () {
      ApiService.setHeader();
      ApiService.get('/shift-work/').then((response) => {
        const listShiftWork = response.data.data;
        this.shiftWorkOptions = listShiftWork.map((element) => {
          return {
            id: element.id,
            name: element.name,
            fromHour: element.fromHour,
            toHour: element.toHour,
            suggestionName: `${element.name} ( ${element.fromHour} - ${element.toHour})`,
          };
        });
        this.filteredShiftWorkOptions = [...this.shiftWorkOptions];
      });
    },
    create() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const data = this.getData();

      ApiService.setHeader();
      ApiService.post('shift-work-config/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.$emit('fetchShiftWorkConfig');
            setTimeout(() => {
              this.hideModal();
            }, 1000);
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.data ? response.data.message : MESSAGE_ERROR);
        });
    },
    submit() {
      switch (this.typeAction) {
        case this.ACTION.UPDATE:
          this.update()
          break;
        case this.ACTION.CREATE:
          this.create()
          break;
        default:
          this.upsert();
          break; 
      }
    },
    update() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const data = this.getData();
      ApiService.setHeader();
      ApiService.put('shift-work-config/', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            setTimeout(() => {
              this.hideModal();
              this.$emit('fetchShiftWorkConfig');
            }, 1000);
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.data ? response.data.message : MESSAGE_ERROR);
        });
    },
    upsert() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const data = this.getData();
      ApiService.setHeader();
      ApiService.put('shift-work-config/upsert-shiftWork-config', data)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            setTimeout(() => {
              this.hideModal();
              this.$emit('fetchShiftWorkConfig');
            }, 1000);
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.data ? response.data.message : MESSAGE_ERROR);
        });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          this.storeOptions = stores.map((item) => {
            return {
              id: item.id,
              suggestionName: item.name,
              name: item.name,
            };
          });
          if (stores.length === 1 && !this.storeId) {
            this.form.storeId = stores[0].id;
            this.form.selectedStore = stores[0].name;
            this.getListEmployeeByStore();
          } else {
            this.filteredStoreOptions = [...this.storeOptions];
          }
        }
      });
    },
    fetchStore: async function () {
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.storeOptions = stores.map((item) => {
          return {
            id: item.id,
            suggestionName: item.name,
            name: item.name,
          };
        });

        this.filteredStoreOptions = [...this.storeOptions];
      });
    },

    getListEmployeeByStore() {
      let listStoreId = [];
      listStoreId.push(this.form.storeId);
      ApiService.setHeader();
      ApiService.get(
        `employees/get-by-store?employeeName=&storeId=${listStoreId}`
      ).then((data) => {
        const employees = data.data.data;
        this.employeesOptions = employees.map((element) => {
          return {
            id: element.id,
            suggestionName: element.code
              ? `${element.fullName} - ${element.code}`
              : element.fullName,
            fullName: element.fullName,
            jobTitleId: element.jobTitleId,
          };
        });
        this.filteredEmployeeOptions = [...this.employeesOptions];
        this.managerOptions = [];
        this.assistantOptions = [];
        this.employeesOptions.forEach((element) => {
          if (element.jobTitleId === JOB_TITLE.QL) {
            this.managerOptions.push(element);
          } else if (element.jobTitleId === JOB_TITLE.TL) {
            this.assistantOptions.push(element);
          }
        });
        this.filteredManagerOptions = [...this.managerOptions];
        this.filteredAssistantOptions = [...this.assistantOptions];
      });
    },
    onInputStoreChange(text) {
      this.form.selectedStore = text;
      const filterData = this.storeOptions
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredStoreOptions = [...filterData];
      if (this.form.selectedStore === '') {
        this.filteredStoreOptions = [...this.storeOptions];
        this.form.storeId = null;
      }
      if (!this.form.selectedStore) {
        this.form.storeId = null;
      }
    },
    onInputManagerChange(text) {
      this.selectedManager = text;
      const filterData = this.filteredManagerOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredManagerOptions = [...filterData];
      if (this.selectedManager == '') {
        this.filteredManagerOptions = [...this.managerOptions];
        this.managerId = null;
      }
      if (!this.form.selectedStore) {
        this.managerId = null;
      }
    },
    onInputAssistantChange(text) {
      this.selectedAssistant = text;
      const filterData = this.filteredAssistantOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredAssistantOptions = [...filterData];
      if (this.selectedAssistant == '') {
        this.filteredAssistantOptions = [...this.assistantOptions];
        this.assistantId = null
      }
      if (!this.selectedAssistant) {
        this.assistantId = null;
      }
    },
    onInputTechnicalChange(text) {
      this.selectedTechnical = text;
      const filterData = this.filteredEmployeeOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredEmployeeOptions = [...filterData];
      if (this.selectedTechnical == '') {
        this.filteredEmployeeOptions = [...this.employeesOptions];
        this.technicalId = null;
      }
      if (!this.selectedTechnical) {
        this.technicalId = null;
      }
    },
    onInputAccessoryStaffChange(text) {
      this.selectedAccessoryStaff = text;
      const filterData = this.filteredEmployeeOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredEmployeeOptions = [...filterData];
      if (this.selectedAccessoryStaff == '') {
        this.filteredEmployeeOptions = [...this.employeesOptions];
        this.accessoryStaffId = null;
      }
      if (!this.selectedTechnical) {
        this.accessoryStaffId = null;
      }
    },
    onInputCustomerReceotionChange(text) {
      this.selectedCustomerReception = text;
      const filterData = this.filteredEmployeeOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredEmployeeOptions = [...filterData];
      if (this.selectedCustomerReception == '') {
        this.filteredEmployeeOptions = [...this.employeesOptions];
        this.customerReceptionId = null;
      }
      if (!this.selectedCustomerReception) {
        this.customerReceptionId = null;
      }
    },
    onInputCashierChange(text) {
      this.selectedCashier = text;
      const filterData = this.filteredEmployeeOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredEmployeeOptions = [...filterData];
      if (this.selectedCashier == '') {
        this.filteredEmployeeOptions = [...this.employeesOptions];
        this.cashierId = null;
      }
      if (!this.selectedCashier) {
        this.cashierId = null;
      }
    },
    onInputShipperChange(text) {
      this.selectedShipper = text;
      const filterData = this.filteredEmployeeOptions
        .filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
      this.filteredEmployeeOptions = [...filterData];
      if (this.selectedShipper == '') {
        this.filteredEmployeeOptions = [...this.employeesOptions];
        this.shipperId = null;
      }
      if (!this.selectedShipper) {
        this.shipperId = null;
      }
    },
    onSelected(type, option) {
      switch (type) {
        case 'Store': {
          this.form.selectedStore = option.item.name;
          this.form.storeId = option.item.id;
          this.resetEmplployee();
          this.getListEmployeeByStore();
          break;
        }
        case 'Manager': {
          this.selectedManager = option.item.fullName;
          this.managerId = option.item.id;
          break;
        }
        case 'Assistant': {
          this.selectedAssistant = option.item.fullName;
          this.assistantId = option.item.id;
          break;
        }
        case 'Technical': {
          this.selectedTechnical = option.item.fullName;
          this.technicalId = option.item.id;
          this.filteredEmployeeOptions = [...this.employeesOptions];
          break;
        }
        case 'AccessoryStaff': {
          this.selectedAccessoryStaff = option.item.fullName;
          this.accessoryStaffId = option.item.id;
          this.filteredEmployeeOptions = [...this.employeesOptions];
          break;
        }
        case 'CustomerReception': {
          this.selectedCustomerReception = option.item.fullName;
          this.customerReceptionId = option.item.id;
          this.filteredEmployeeOptions = [...this.employeesOptions];
          break;
        }
        case 'Cashier': {
          this.selectedCashier = option.item.fullName;
          this.cashierId = option.item.id;
          this.filteredEmployeeOptions = [...this.employeesOptions];
          break;
        }
        case 'Shipper': {
          this.selectedShipper = option.item.fullName;
          this.shipperId = option.item.id;
          this.filteredEmployeeOptions = [...this.employeesOptions];
          break;
        }
        case 'ShiftWork': {
          this.form.selectedShiftWork = option.item.suggestionName;
          this.form.shiftWorkId = option.item.id;
          break;
        }
        default:
          break;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetEmplployee() {
      this.managerId = null;
      this.selectedManager = '';
      this.assistantId = null;
      this.selectedAssistant = '';
      this.technicalId = null;
      this.selectedTechnical = '';
      this.accessoryStaffId = null;
      this.selectedAccessoryStaff = '';
      this.cashierId = null;
      this.selectedCashier = '';
      this.customerReceptionId = null;
      this.selectedCustomerReception = '';
      this.shipperId = null;
      this.selectedShipper = '';
    },
    resetModal() {
      this.form.storeId = null;
      this.form.shiftWorkId = null;
      this.form.selectedShiftWork = '';
      this.form.selectedStore = '';
      this.managerId = null;
      this.selectedManager = '';
      this.assistantId = null;
      this.selectedAssistant = '';
      this.technicalId = null;
      this.selectedTechnical = '';
      this.accessoryStaffId = null;
      this.selectedAccessoryStaff = '';
      this.cashierId = null;
      this.selectedCashier = '';
      this.customerReceptionId = null;
      this.selectedCustomerReception = '';
      this.shipperId = null;
      this.selectedShipper = '';
      this.form.applyDate = null;
    },

    hideModal() {
      this.$bvModal.hide('modal-upsert-shiftWork');
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      selectedStore: {
        required,
      },
      storeId: {
        required,
      },
      selectedShiftWork: {
        required,
      },
      shiftWorkId: {
        required,
      },
      applyDate: {
        required,
      }
    },
  },

  components: {
    Autosuggest,
    datePicker,
  },
};
</script>

<style>
input.form-control {
  border: 1px solid #ced4da;
}
</style>
