<template>
  <div>
    <Autosuggest
      :model="storeNameModel"
      :suggestions="filteredOptionsStore"
      :placeholder="'tên cửa hàng'"
      :state="validateStore"
      :disable="disable"
      :limit="10"
      @select="onSelectedStore"
      @change="onInputChangeStore"
    />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  props: ['onSubmiting', 'storeId', 'storeName', 'disable'],
  data() {
    return {
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
    };
  },
  watch: {},
  components: {
    Autosuggest,
  },
  computed: {
    validateStore() {
      return !this.storeId && this.onSubmiting ? false : true;
    },
    storeNameModel: {
      get: function () {
        return this.storeName;
      },
      set: function (newValue) {
        this.$emit('update:storeName', newValue);
      },
    },
    storeIdModel: {
      get: function () {
        return this.storeId;
      },
      set: function (newValue) {
        this.$emit('update:storeId', newValue);
      },
    },
  },
  mounted() {
    this.fetchStoreByUser();
    this.storeNameModel = this.storeName ? this.storeName : '';
  },
  methods: {
    onSelectedStore(option) {
      if (option) {
        this.storeIdModel = option.item.id;
        this.storeNameModel = option.item.name;
        this.fetchStoreByUser();
        this.$emit('onStoreSelected', option.item.id);
      }
    },
    onInputChangeStore(text) {
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = filteredData;
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1 && !this.storeId) {
            this.storeIdModel = stores[0].id;
            this.storeNameModel = stores[0].name;
          } else {
            this.optionsStore[0].data = stores;
            const tmp = this.optionsStore[0].data;
            this.filteredOptionsStore = tmp;
          }
        }
      });
    },
  },
};
</script>
