<template>
  <div>
    <b-form-group>
      <label>Số PO- hợp đồng:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="whosesaleContractModel.contractCode"
        placeholder="Nhập số PO- hợp đồng"
      ></b-form-input>
    </b-form-group>
    <b-form-group>
      <label>Ngày ký hợp đồng:</label>
      <div class="input-group">
        <date-picker
          placeholder="Ngày ký hợp đồng"
          class="form-control-sm input-style"
          :config="dpConfigs"
          v-model="whosesaleContractModel.contractSigningDate"
          :wrap="true"
        ></date-picker>
        <div class="input-group-append">
          <b-button
            class="datepickerbutton"
            variant="secondary"
            size="sm"
            title="Toggle"
          >
            <i class="far fa-calendar"></i>
          </b-button>
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { DP_CONFIG } from '@/utils/date';
import { cloneDeep } from '@/utils/common';
export default {
  props: ['billData', 'disabled'],
  data() {
    return {
      dpConfigs: DP_CONFIG.date,
      whosesaleContractModel: {
        contractCode: '',
        contractSigningDate: '',
      },
    };
  },
  watch: {
    whosesaleContractModel: {
      handler: function (newValue) {
        this.billData.whosesaleContractModel = newValue;
      },
      deep: true,
    },
  },
  created() {
    this.whosesaleContractModel = cloneDeep(
      this.billData.whosesaleContractModel,
    );
  },
};
</script>
