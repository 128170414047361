<template>
  <div class="customer-common">
    <div>
      <b-form-group>
        <label>Điện thoại:<b class="text-danger">*</b> </label>
        <i
          v-show="customerModel.customerId"
          @click="showPopoverCustomer = !showPopoverCustomer"
          class="far fa-caret-square-down text-warning ml-2"
          id="popover-button-sync"
          style="cursor: pointer"
        ></i>
        <Autosuggest
          :model="customerModel.customerMobile"
          :suggestions="filteredOptionsCustomer"
          :suggestionName="'phoneNumber'"
          placeholder="số điện thoại"
          :limit="10"
          @select="onSelectCustomer"
          @change="onSearchChange"
        >
          <template #custom="{ suggestion }">
            <div>
              <div class="d-flex flex-column">
                <span
                  class="text-primary mb-1"
                  v-if="suggestion.item.phoneNumber"
                  >({{ suggestion.item.phoneNumber }})</span
                >
                <span v-if="suggestion.item.fullName">{{
                  suggestion.item.fullName
                }}</span>
                <span v-else>{{ suggestion.item.message }}</span>
              </div>
            </div>
          </template>
        </Autosuggest>
        <b-form-invalid-feedback
          :state="validateCustomer"
          id="input-phone-live-feedback"
          >Vui lòng nhập số điện thoại hợp lệ!</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group>
        <label>
          Họ và tên:<b class="text-danger">*</b>
          <!-- <span class="text-primary" v-html="pointText"></span> -->
        </label>
        <b-form-input
          class="input-style"
          size="sm"
          type="text"
          v-model="customerModel.customerName"
          placeholder="Tên khách hàng"
        ></b-form-input>
      </b-form-group>
      <b-form-group>
        <div class="type-appellation">
          <div class="appellation">
            <label for="input-name"
              >Danh xưng:<b class="text-danger">*</b>
            </label>
            <treeselect
              :options="appellationOptions"
              v-model="customerModel.customerAppellation"
              :multiple="false"
              placeholder="Chọn danh xưng"
              noResultsText="Không có kết quả"
              :clearable="false"
              size="sm"
            ></treeselect>
          </div>
          <!-- <div class="type">
            <label for="input-name"> Loại khách: </label>
            <treeselect
              :options="optionsCustomerType"
              :multiple="false"
              placeholder="Chọn loại khách"
              noResultsText="Không có kết quả"
              :match-keys="['label', 'custom']"
              v-model="customerModel.customerType"
              :clearable="false"
              openDirection="bottom"
            >
            </treeselect>
          </div> -->
        </div>
      </b-form-group>
      <!-- <b-form-group>
        <label>Thành phố:</label>
        <treeselect
          :options="listCity"
          :multiple="false"
          placeholder="Chọn thành phố"
          noResultsText="Không có kết quả"
          :match-keys="['label', 'custom']"
          v-model="customerModel.customerCity"
          openDirection="bottom"
        >
        </treeselect>
      </b-form-group>
      <b-form-group>
        <label>Quận huyện:</label>
        <treeselect
          :options="listDistrict"
          :multiple="false"
          placeholder="Chọn tỉnh thành"
          noResultsText="Không có kết quả"
          :match-keys="['label', 'custom']"
          v-model="customerModel.customerDistrict"
          openDirection="bottom"
          :disabled="listDistrict.length ? false : true"
        >
        </treeselect>
      </b-form-group>
      <b-form-group>
        <label>Phường xã:</label>
        <treeselect
          :options="listWard"
          :multiple="false"
          placeholder="Chọn phường xã"
          noResultsText="Không có kết quả"
          :match-keys="['label', 'custom']"
          v-model="customerModel.customerWard"
          openDirection="bottom"
          :disabled="listWard.length ? false : true"
        >
        </treeselect>
      </b-form-group> -->
      <b-form-group>
        <label>Địa chỉ:</label>
        <b-form-textarea
          v-if="fromOrder"
          v-model="customerModel.customerAddress"
          size="sm"
          class="input-style"
          :placeholder="'Địa chỉ khách hàng'"
          :rows="6"
          :max-rows="6"
        ></b-form-textarea>
        <b-form-input
          class="input-style"
          size="sm"
          type="text"
          v-model="customerModel.customerAddress"
          placeholder="Địa chỉ khách hàng"
          v-else
        ></b-form-input>
      </b-form-group>
    </div>
    <div>
      <div>
        <b-popover
          :show.sync="showPopoverCustomer"
          target="popover-button-sync"
          title="Lịch sử khách hàng"
          triggers="hover focus"
          id="popover-customer"
        >
          <div>
            <a
              @click="copyCustomerMobile"
              class="mt-1 d-block"
              v-if="customerModel.customerMobile.length > 8"
              ><b class="text-dark">Số điện thoại :</b>
              {{ customerModel.customerMobile }}
              <i
                class="far fa-copy text-primary cursor-pointer ml-1"
                style="font-size: 0.85rem"
              ></i>
            </a>
            <a href="#" class="mt-1 d-block"
              ><b class="text-dark">Đơn hàng :</b>
              {{ customerTransaction.orderCount }}</a
            >
            <a href="#" class="mt-1 d-block"
              ><b class="text-dark">Bán lẻ :</b>
              {{ customerTransaction.retailCount }}</a
            >
            <a href="#" class="mt-1 d-block"
              ><b class="text-dark">Bán buôn:</b>
              {{ customerTransaction.wholesaleCount }}</a
            >
            <a href="#" class="mt-1 d-block"
              ><b class="text-dark">Trả hàng:</b>
              {{ customerTransaction.refundCount }}</a
            >
          </div>
          <div class="mt-3 text-center">
            <span class="text-link" @click="viewCustomerTransaction"
              >Chi tiết</span
            >
          </div>
        </b-popover>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { CUSTOMER_TYPE } from '@/utils/constants';
import { cloneDeep } from 'lodash';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { removeAccents, copyClipBoard } from '@/utils/common';
import { mapGetters } from 'vuex';
import { SET_CUSTOMER_ORDER_INFO } from '@/core/services/store/modules/order/order-customer-info.module.js';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import decounce from 'debounce';

export default {
  props: ['onSubmiting', 'billData', 'fromOrder', 'action'],
  data() {
    return {
      listCity: [],
      listDistrict: [],
      listWard: [],
      selectedCustomerSource: null,
      listCustomerSource: [],
      customerModel: {
        customerId: null,
        customerMobile: '',
        customerName: '',
        customerLastName: '',
        customerAddress: '',
        customerCity: null,
        customerDistrict: null,
        customerWard: null,
        customerType: 1,
        customerAppellation:null,
        totalPoint: 0,
      },
      optionsCustomerType: [],
      appellationOptions: [
        { id: null, label: 'Chọn danh xưng' },
        { id: 1, label: 'Anh' },
        { id: 2, label: 'Chị' },
      ],
      showPopoverCustomer: false,
      defaultOptionCustomers: null,
      skipLoaded: false,
      isOpenCustomerSearch: false,
      customerTransaction: {
        orderCount: 0,
        retailCount: 0,
        wholesaleCount: 0,
        refundCount: 0,
      },
      filteredOptionsCustomer: [],
      optionsCustomer: [],
    };
  },
  components: {
    Treeselect,
    Autosuggest,
  },
  created() {
    this.mappingEnum();
    this.fetchCity();
    if (this.action === 'update') {
      const customerModel = this.getOrderCustomerInfo;
      this.defaultOptionCustomers = [
        {
          id: customerModel.customerId,
          label: customerModel.customerMobile,
          customLabel: customerModel.customerName,
        },
      ];
      this.customerModel = customerModel;
      this.getCustomerInfoUpdatedById(this.customerModel.customerId);
    }
    this.$nextTick(() => {
      this.skipLoaded = true;
    });
  },
  mounted() {
    if (!this.fromOrder) {
      this.customerModel = cloneDeep(this.billData.customerModel);
      this.getCustomerInfoUpdatedById(this.customerModel.customerId);
    }
  },
  computed: {
    ...mapGetters(['getOrderCustomerInfo']),
    validateCustomer() {
      if (!this.onSubmiting) {
        return true;
      }

      if (
        !this.customerModel.customerMobile ||
        this.customerModel.customerMobile.trim().length < 8 ||
        this.customerModel.customerMobile.trim().length > 15
      ) {
        return false;
      }

      return true;
    },
    pointText: {
      get() {
        return `<b>(Tổng điểm: ${this.customerModel.totalPoint || 0})</b>`;
      },
      set(newValue) {
        return newValue ? newValue : '';
      },
    },
  },
  watch: {
    'customerModel.customerMobile': function (newValue) {
      if (this.skipLoaded) {
        if (!newValue) {
          this.customerModel.customerName = null;
          this.pointText = null;
        }
      }
    },
    'customerModel.customerCity': function (city) {
      if (this.skipLoaded) {
        this.listDistrict = [];
        this.listWard = [];
        this.customerModel.customerDistrict = null;
        this.customerModel.customerWard = null;
        if (city) {
          this.getDistrict();
        }
      }
    },
    'customerModel.customerDistrict': function (district) {
      if (this.skipLoaded) {
        this.listWard = [];
        this.customerModel.customerWard = null;
        if (district) {
          this.getWard();
        }
      }
    },
    customerModel: {
      handler: function (value, oldValue) {
        console.log(value, 21312312);

        if (this.skipLoaded) {
          this.$store.dispatch(SET_CUSTOMER_ORDER_INFO, value);
          this.$emit('onUpdate', value);
        }
        if (!oldValue) {
          this.getTransactionByCustomerId(value.customerId);
        } else if (oldValue && value !== oldValue) {
          this.getTransactionByCustomerId(value.customerId);
        }
      },
      deep: true,
    },
  },
  methods: {
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    mappingEnum() {
      this.optionsCustomerType = cloneDeep(CUSTOMER_TYPE).reduce(
        (result, element) => {
          if (element.value !== null) {
            result.push({
              id: element.id,
              label: element.name,
              custom: removeAccents(element.name),
            });
          }
          return result;
        },
        [],
      );
    },
    copyCustomerMobile() {
      const customerMobile = this.customerModel.customerMobile || '';
      if (customerMobile.length > 8) {
        copyClipBoard(customerMobile, 'Số điện thoại');
      }
    },
    onSelectCustomer(option) {
      if (option.item && option.item.id) {
        this.customerModel.customerId = option.item.id;
        this.customerModel.customerMobile = option.item.phoneNumber;
        this.customerModel.customerAppellation = option.item.gender;
        this.getCustomerInfoById(option.item.id);
        // this.getTransactionByCustomerId(option.item.id);
      } else {
        this.customerModel.customerId = '';
        this.customerModel.customerMobile = option.item.phoneNumber;
        this.customerModel.customerName = '';
        this.customerModel.totalPoint = 0;
      }
    },
    onSearchChange(searchQuery) {
      if (!searchQuery) {
        searchQuery = '';
      }
      this.customerModel.customerMobile = searchQuery;
      this.optionsCustomer = [];
      this.filteredOptionsCustomer = [...this.optionsCustomer];
      this.debounceInput();
    },
    getListCustomer() {
      const text = this.customerModel.customerMobile
        ? this.customerModel.customerMobile.trim()
        : null;
      if (!text) {
        return;
      }
      ApiService.get(`customers/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer = data.data.data;
          if (this.optionsCustomer.length > 0) {
            this.filteredOptionsCustomer = [...this.optionsCustomer];
          } else {
            this.filteredOptionsCustomer = [
              { message: 'SĐT không có trong hệ thống', phoneNumber: text },
            ];
          }
        },
      );
    },
    async loadOptionCustomers({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        this.customerModel.customerMobile = searchQuery;

        const result = await this.fetchCustomers(searchQuery);

        let optionCustomers = [];
        if (result.data.length) {
          optionCustomers = (result.data || []).map((element) => {
            return {
              id: element.id,
              label: element.phoneNo,
              customLabel: element.fullName,
            };
          });
        } else {
          optionCustomers = [
            {
              id: -1,
              label: searchQuery,
              customLabel: 'SĐT không có trong hệ thống',
              notFound: true,
            },
          ];
        }
        callback(null, optionCustomers);
      }
    },
    fetchCustomers(searchQuery) {
      const textInput = searchQuery ? searchQuery.trim() : null;

      if (!textInput) {
        return;
      }

      const params = {
        phoneNumber: textInput,
      };

      return ApiService.query(`customers/get-by-phone`, {
        params,
      }).then((response) => {
        return response.data;
      });
    },
    fetchCity() {
      ApiService.get('city/').then((data) => {
        const cities = data.data.data;
        this.listCity = cloneDeep(cities).reduce((result, element) => {
          if (element.value !== null) {
            result.push({
              id: element.id,
              label: element.name,
              custom: removeAccents(element.name),
            });
          }
          return result;
        }, []);
      });
    },
    getDistrict() {
      ApiService.get(`city/${this.customerModel.customerCity}/district`).then(
        (data) => {
          const districts = data.data.data;
          this.listDistrict = cloneDeep(districts).reduce((result, element) => {
            if (element.value !== null) {
              result.push({
                id: element.id,
                label: element.name,
                custom: removeAccents(element.name),
              });
            }
            return result;
          }, []);
        },
      );
    },
    getWard() {
      ApiService.get(`city/${this.customerModel.customerDistrict}/ward`).then(
        (data) => {
          const wards = data.data.data;
          this.listWard = cloneDeep(wards).reduce((result, element) => {
            if (element.value !== null) {
              result.push({
                id: element.id,
                label: element.name,
                custom: removeAccents(element.name),
              });
            }
            return result;
          }, []);
        },
      );
    },
    getCustomerInfoUpdatedById(customerId) {
      if (!customerId) {
        return;
      }
      ApiService.get(`customers/${customerId}`).then((response) => {
        const customerInfo = response.data.data;
        const customerName = this.customerModel.customerName
          ? this.customerModel.customerName.trim()
          : null;
        const customerAddress = this.customerModel.customerAddress
          ? this.customerModel.customerAddress.trim()
          : null;
        this.customerModel.customerName = customerName || customerInfo.fullName;
        this.customerModel.customerCity = customerInfo.city;
        this.customerModel.customerWard = customerInfo.ward;
        this.customerModel.customerDistrict = customerInfo.district;
        this.customerModel.customerAddress =
          customerAddress || customerInfo.address;
        this.customerModel.customerType = customerInfo.type;
        this.customerModel.customerAppellation =
          customerInfo.gender || null;
        this.customerModel.totalPoint = customerInfo.totalPoint || 0;

        if (this.customerModel.customerCity !== null) {
          this.getDistrict();
        }
        if (this.customerModel.customerDistrict !== null) {
          this.getWard();
        }
        this.customerModel.customerLastName = customerInfo.lastName;
      });
    },
    getCustomerInfoById(customerId) {
      if (customerId) {
        ApiService.get(`customers/${customerId}`).then((response) => {
          const customerInfo = response.data.data;
          this.customerModel.customerName = customerInfo.fullName;
          // this.customerModel.customerCity = customerInfo.city;
          // this.customerModel.customerWard = customerInfo.ward;
          // this.customerModel.customerDistrict = customerInfo.district;
          this.customerModel.customerAddress = customerInfo.address;
          // this.customerModel.customerType = customerInfo.type
          //   ? customerInfo.type
          //   : '';
          // if (this.customerModel.customerCity !== null) {
          //   this.getDistrict();
          // }
          // if (this.customerModel.customerDistrict !== null) {
          //   this.getWard();
          // }
          // this.customerModel.totalPoint = customerInfo.totalPoint;
          // const totalPoint = Number(customerInfo.totalPoint) || 0;
          // this.pointText = ` <b>(Tổng điểm:  ${totalPoint})</b>`;
          // this.customerModel.customerLastName = customerInfo.lastName;
        });
      }
    },
    getTransactionByCustomerId(customerId) {
      if (!customerId) {
        return;
      }
      ApiService.get(`customer/transaction/${customerId}`).then((response) => {
        const data = response.data.data;
        this.customerTransaction.orderCount = data.orders.length;
        this.customerTransaction.retailCount = data.retails.length;
        this.customerTransaction.wholesaleCount = data.wholesales.length;
        this.customerTransaction.refundCount = data.refunds.length;
        this.$emit('fetch-customer-transaction', data);
      });
    },
    viewCustomerTransaction: function () {
      const { href } = this.$router.resolve({
        name: 'update-customer',
        query: {
          id: this.customerModel.customerId,
          phoneNo: this.customerModel.customerMobile,
        },
      });
      window.open(href, '_blank');
    },
  },
};
</script>

<style scoped>
.type-appellation {
  display: flex;
  gap: 4px;
}

.border-bottom {
  border-bottom: 0.5px solid #e9e9e9;
}

.btn-group-customer-info .btn {
  padding: 0.05rem 0.05rem;
}

.vue-treeselect__input-container {
  padding-top: 0px;
}

.vue-treeselect__control {
  border-radius: 0.28rem;
  border: 1px solid #ced4da;
  height: 2.5rem;
}

.vue-treeselect--has-value .vue-treeselect__multi-value {
  margin-bottom: 2px;
}
.vue-treeselect__tip .vue-treeselect__no-results-tip {
  display: none;
}
</style>

<style lang="scss">
#popover-customer {
  .text-link:hover {
    cursor: pointer;
    color: blue;
  }
}
</style>
