<template>
  <div class="whosesale-bill-info">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <b-card
          no-body
          class="full-width"
        >
          <div>
            <div class="wizard-nav-bg">
              <b-col
                class="py-2"
                cols="12"
                style="border-bottom: 0.5px solid #e7e7e7"
              >
                <b-row>
                  <b-col><b>{{ pageTitle ? pageTitle : '#' }}</b>
                  </b-col>
                  <b-col>
                    <div class="d-flex justify-content-end mr-3">
                      <b-button-group
                        v-if="shiftWorkConfig && mainModel.editable"
                        class="font-weight-bold ml-3"
                      >
                        <b-button
                          v-b-tooltip.hover
                          title="Chọn nhân viên theo cấu hình theo ca làm việc"
                          @click="onShiftWorkConfigSelected"
                        >{{ shiftWorkConfig.name }}</b-button>
                        <b-button
                          @click="showShiftWorkConfigModal"
                          v-b-modal.modal-upsert-shiftWork
                        >
                          <i
                            style="font-size: 1rem"
                            class="fa fa-cog"
                          ></i>
                        </b-button>
                      </b-button-group>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4_bill"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div
                class="wizard-nav wizard-nav-bg"
                style="border-radius: 0px"
              >
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper p-2">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper p-2">
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper p-2">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <b-skeleton-wrapper :loading="!renderComponent">
                  <!-- <b-skeleton-wrapper :loading="true"> -->
                  <template #loading>
                    <BillSkeleton />
                  </template>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-12">
                        <div
                          class="py-4 mb-5 full-width"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          <b-form
                            ref="form"
                            lazy-validation
                            class="row"
                          >
                            <b-row
                              class="col-md-12 mb-1 m-0 p-0"
                              v-if="mainModel.orderId"
                            >
                              <div
                                class="alert-header"
                                style="width: 100%"
                              >
                                Chuyển đơn hàng
                                <span
                                  class="text-primary"
                                  style="cursor: pointer"
                                  @click="linkToOrder(mainModel.orderId)"
                                >{{ mainModel.orderId }}</span>
                                sang hóa đơn bán buôn .
                                <ul style="
                                    padding-left: 15px;
                                    line-height: 20px;
                                    margin-top: 5px;
                                    list-style: circle;
                                  ">
                                  <li v-show="mainModel.moneyPaid > 0">
                                    Số tiền đã đặt cọc:
                                    <span class="text-primary">
                                      {{ convertPrice(mainModel.moneyPaid) }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.moneyTransfered > 0">
                                    Số tiền đã chuyển khoản:
                                    <span class="text-primary">
                                      {{
                                        convertPrice(mainModel.moneyTransfered)
                                      }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.moneyCredit > 0">
                                    Số tiền quẹt thẻ:
                                    <span class="text-primary">
                                      {{ convertPrice(mainModel.moneyCredit) }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.moneyInstallment > 0">
                                    Số tiền đã trả góp:
                                    <span class="text-primary">
                                      {{
                                        convertPrice(mainModel.moneyInstallment)
                                      }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.customerShipFee > 0">
                                    Phí ship:
                                    <span class="text-primary">
                                      {{
                                        convertPrice(mainModel.customerShipFee)
                                      }}
                                    </span>
                                  </li>
                                </ul>
                                Mã vận đơn:
                                <span class="text-primary">
                                  {{ mainModel.codeShip }}
                                </span>
                              </div>
                            </b-row>
                            <b-row
                              class="col-md-12 mb-1 m-0 p-0"
                              v-if="
                                !mainModel.id &&
                                  !mainModel.orderId &&
                                  orderNotComplete
                              "
                            >
                              <div
                                class="alert-header"
                                style="width: 100%"
                              >
                                Khách hàng đang có đơn hàng chưa hoàn tất:
                                <span class="text-primary">#{{ orderNotComplete.id }}</span>
                                -
                                <span
                                  v-text="orderNotComplete.statusName"
                                  class="text-warning"
                                ></span>
                              </div>
                            </b-row>
                            <b-row
                              class="col-md-12 mb-1 m-0 p-0"
                              v-if="getInstallmentInterestZero"
                            >
                              <div
                                class="alert-header"
                                style="width: 100%"
                              >
                                <i class="fa fa-exclamation-triangle text-warning"></i>
                                <strong class="text-dark">
                                  Chuyển sang cấu hình trả góp 0%</strong>
                                <span
                                  class="text-primary ml-2"
                                  style="cursor: pointer"
                                  @click="onShowInterestProgram"
                                >Xem chương trình</span>
                              </div>
                            </b-row>
                            <b-row class="col-md-12 mb-1 m-0 p-0">
                              <b-col
                                md="8"
                                class="mb-2"
                              >
                                <b-row>
                                  <b-col
                                    md="3"
                                    class="p-0"
                                  >
                                    <b-form-select
                                      v-model="selectTypeSearch"
                                      class="select-style"
                                      :options="listTypeSearch"
                                      size="sm"
                                      value-field="id"
                                      text-field="name"
                                      @change="changePlaceholder"
                                    ></b-form-select>
                                  </b-col>
                                  <b-col
                                    md="9"
                                    class="p-0"
                                  >
                                    <vue-autosuggest
                                      v-model="searchProduct"
                                      :suggestions="filteredOptionsProduct"
                                      @selected="onSelectedProduct"
                                      :limit="20"
                                      @input="onInputChangProduct"
                                      :input-props="inputSearchProductProp"
                                      :should-render-suggestions="
                                        (size, loading) => !loading
                                      "
                                      :get-suggestion-value="
                                        (suggestion) =>
                                          suggestion.item.productName
                                      "
                                    >
                                      <div
                                        v-if="selectTypeSearch === 1"
                                        slot-scope="{ suggestion }"
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <div>
                                          <span
                                            v-if="getInstallmentInterestZero"
                                            class="text-warning"
                                          >
                                            (
                                            {{
                                              convertPrice(
                                                suggestion.item.listedPrice,
                                              )
                                            }}
                                            )</span>
                                          <span
                                            v-else
                                            class="text-primary"
                                          >({{
                                              convertPrice(
                                                suggestion.item.sellingPrice,
                                              )
                                            }})
                                          </span>
                                          <span
                                            v-if="
                                              suggestion.item.quantityInStock >
                                                0 &&
                                                suggestion.item.productType !==
                                                  4
                                            "
                                            class="text-success"
                                          >(Tồn CTB:
                                            {{
                                              suggestion.item.quantityInStock
                                            }})</span>
                                          <span
                                            v-if="
                                              suggestion.item.quantityInStock <=
                                                0 &&
                                                suggestion.item.productType !==
                                                  4
                                            "
                                            class="text-danger"
                                          >(Tồn CTB:
                                            {{
                                              suggestion.item.quantityInStock
                                            }})</span>
                                          {{ suggestion.item.productName }}
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="selectTypeSearch === 2"
                                        slot-scope="{ suggestion }"
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <div>
                                          <span class="text-primary">({{
                                              convertPrice(
                                                suggestion.item.sellingPrice,
                                              )
                                            }})</span>
                                          <span
                                            v-if="
                                              suggestion.item.quantityInStock >
                                                0
                                            "
                                            class="text-success"
                                          >(Tồn CTB:
                                            {{
                                              suggestion.item.quantityInStock
                                            }})</span>
                                          {{ suggestion.item.productName }}-
                                          <span class="text-warning">IMEI:
                                            {{ suggestion.item.imeiCode }}</span>
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="selectTypeSearch === 3"
                                        slot-scope="{ suggestion }"
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <div>
                                          <span class="text-success">(Số SP:
                                            {{
                                              suggestion.item.listItem.length
                                            }})</span>
                                          {{ suggestion.item.productName }}
                                        </div>
                                      </div>
                                      <template slot="after-section">
                                        <div
                                          class="p-2 text-center"
                                          v-if="
                                            !filteredOptionsProduct[0].data
                                              .length
                                          "
                                        >
                                          <div v-if="!productLoading">
                                            <img src="../../../assets/image/no-results.png" />
                                            <b class="ml-2">Không có kết quả</b>
                                          </div>
                                          <b-spinner
                                            label="Spinning"
                                            v-if="productLoading"
                                          ></b-spinner>
                                        </div>
                                      </template>
                                    </vue-autosuggest>
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col
                                md="1"
                                class="p-0"
                              >
                                <span
                                  class="input-group-text import-excel-button"
                                  title="Nhập từ Excel"
                                  @click="showModalImportProduct"
                                >
                                  <i
                                    class="fas fa-file-import"
                                    style="color: dimgray"
                                  ></i>
                                </span>
                              </b-col>
                              <b-col
                                md="3"
                                class="mb-3 pr-0"
                              >
                                <SearchStore
                                  :onSubmiting="onSubmiting"
                                  :storeId="mainModel.storeId"
                                  :storeName.sync="mainModel.storeName"
                                  v-on:onStoreSelected="onStoreSelected"
                                  :disable="
                                    !!mainModel.id || mainModel.storeDisable
                                  "
                                  v-if="renderComponent"
                                />
                              </b-col>
                            </b-row>
                            <b-row class="mb-3 m-0 p-0">
                              <b-col
                                md="9"
                                class="mb-3"
                              >
                                <b-row>
                                  <table class="table table-bordered table-vertical-center table-hover">
                                    <thead>
                                      <tr>
                                        <th class="headerTable">STT</th>
                                        <th class="name headerTable">
                                          Sản phẩm
                                        </th>
                                        <th class="value headerTable">SL</th>
                                        <th class="code headerTable">Giá</th>
                                        <th class="code headerTable">
                                          Thành tiền
                                        </th>
                                        <th class="code headerTable">
                                          Chiết khấu
                                        </th>
                                        <th class="code headerTable">VAT</th>
                                        <th></th>
                                      </tr>
                                    </thead>

                                    <tbody
                                      v-for="item in listBillItem"
                                      :key="item.id"
                                    >
                                      <BillItem
                                        v-bind:productItem="item"
                                        v-bind:editable="mainModel.editable"
                                        v-bind:editIMEI="
                                          !mainModel.id &&
                                            !mainModel.fromBillNumber
                                        "
                                        v-on:update="updateItem"
                                        v-on:deleteItem="deleteBillItem"
                                        v-on:checkValidImei="checkValidImei"
                                        v-on:addProductBonus="showGiftModal"
                                        v-on:addProductPromotion="
                                          showAttachModal
                                        "
                                        v-on:addProductWarranty="
                                          showWarrantyModal
                                        "
                                        v-on:changeProductBonus="
                                          showChangeGiftModal
                                        "
                                        v-on:addNote="showNoteModal"
                                        v-on:linkToListImei="showListImeiModal"
                                        v-on:viewInventory="viewInventory"
                                        v-on:addWarranty="linkToWarranty"
                                        v-on:activeImei="showActiveModal"
                                      />
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th
                                          style="text-align: right"
                                          id="total"
                                          colspan="5"
                                        >
                                          <span
                                            class="d-block"
                                            v-if="totalAmountBeforeDiscount"
                                          >Tổng trước chiết khấu:</span>
                                          <span class="d-block">Tổng:</span>
                                        </th>
                                        <td style="
                                            text-align: right;
                                            padding: 0px 20px;
                                          ">
                                          <span
                                            class="d-block"
                                            v-if="totalAmountBeforeDiscount"
                                          >
                                            {{
                                              convertPrice(
                                                totalAmountBeforeDiscount,
                                              )
                                            }}
                                          </span>
                                          <span class="d-block">{{
                                            convertPrice(totalAmount)
                                          }}</span>
                                        </td>
                                        <td style="
                                            text-align: right;
                                            padding: 0px 20px;
                                          "></td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </b-row>
                                <b-row style="margin-left: -25px">
                                  <b-col md="4">
                                    <CustomerInfo
                                      :onSubmiting="onSubmiting"
                                      :billData="mainModel"
                                      :disabled="!mainModel.editable"
                                      v-on:onUpdate="onUpdateCustomer"
                                      v-if="renderComponent"
                                      v-on:fetch-customer-transaction="
                                        fetchCustomerCransaction
                                      "
                                    />
                                    <b-form-group :disabled="!mainModel.editable">
                                      <label>Nguồn khách hàng:</label>
                                      <b-form-select
                                        v-model="mainModel.orderSourceId"
                                        :options="listCustomerSource"
                                        size="sm"
                                        value-field="id"
                                        text-field="name"
                                        class="select-style"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option
                                            :value="null"
                                            disabled
                                          >Chọn nguồn khách
                                            hàng:</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                  </b-col>
                                  <b-col md="4">
                                    <EmployeeInfo
                                      :storeId="mainModel.storeId"
                                      :billDataEmployeeInfo="
                                        mainModel.billEmployeeInfo
                                      "
                                      :disabled="!mainModel.editable"
                                    />
                                  </b-col>
                                  <b-col md="4">
                                    <WhosesaleContractInfo
                                      :billData="mainModel"
                                      :disabled="!mainModel.editable"
                                      v-if="renderComponent"
                                    />
                                    <BillVatInfo
                                      :isValidTaxCode.sync="isValidTaxCode"
                                      :isValidEmail.sync="isValidEmail"
                                      :billData="mainModel"
                                      :disabled="!mainModel.editable"
                                      v-if="renderComponent"
                                    />
                                  </b-col>
                                </b-row>
                                <BillNote
                                  :billNote="mainModel.billNote"
                                  :disabled="!mainModel.editable"
                                  v-if="renderComponent"
                                />
                              </b-col>
                              <b-col
                                md="3"
                                class="mb-3 pr-0"
                              >
                                <b-form-group :disabled="!mainModel.editable">
                                  <label class="lable-form">Chiết khấu:</label>
                                  <b-row>
                                    <b-col
                                      class="pr-0"
                                      cols="3"
                                    >
                                      <b-form-select
                                        class="select-style"
                                        v-model="mainModel.discountType"
                                        :options="listAmountType"
                                        size="sm"
                                        value-field="id"
                                        text-field="name"
                                      >
                                      </b-form-select>
                                    </b-col>
                                    <b-col
                                      class="pl-0"
                                      cols="9"
                                    >
                                      <b-form-input
                                        class="input-style text-right"
                                        size="sm"
                                        v-model="mainModel.discountAmount"
                                        v-mask="mask"
                                        placeholder="Chiết khấu"
                                      ></b-form-input>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                                <div class="row-center-vertical">
                                  <p class="textPayment">Thanh toán</p>
                                </div>
                                <b-row class="mb-1">
                                  <b-col md="6">
                                    <span class="textTitlePayment">Tổng tiền:</span>
                                  </b-col>
                                  <b-col
                                    md="6"
                                    class="text-right"
                                  >
                                    <span class="textPayment">
                                      {{ convertPrice(totalAmount) }}
                                    </span>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-if="totalAmountReturn > 0"
                                  class="mb-1"
                                >
                                  <b-col md="6">
                                    <span class="textTitlePayment">
                                      Tiền thu lại:
                                    </span>
                                  </b-col>
                                  <b-col
                                    md="6"
                                    class="text-right"
                                  >
                                    <span class="textPayment">
                                      {{ convertPrice(totalAmountReturn) }}
                                    </span>
                                  </b-col>
                                </b-row>
                                <b-row
                                  v-if="totalPaid > 0"
                                  class="mb-1"
                                >
                                  <b-col md="6">
                                    <span class="textTitlePayment">
                                      Khách đã đưa:
                                    </span>
                                  </b-col>
                                  <b-col
                                    md="6"
                                    class="text-right"
                                  >
                                    <span class="textPayment">
                                      {{ convertPrice(totalPaid) }}
                                    </span>
                                  </b-col>
                                </b-row>
                                <b-row class="mb-1">
                                  <b-col md="6">
                                    <span class="textTitlePayment">
                                      Khách cần trả:
                                    </span>
                                  </b-col>
                                  <b-col
                                    md="6"
                                    class="text-right"
                                  >
                                    <span class="textPayment">
                                      {{ convertPrice(finalAmount) }}
                                    </span>
                                  </b-col>
                                </b-row>
                                <BillPayment
                                  ref="bill-payment"
                                  v-if="renderComponent"
                                  :finalAmount="finalAmount"
                                  :billData="mainModel"
                                  :onSubmiting="onPaymentSubmiting"
                                  :disabled="!mainModel.editable"
                                  :isAllowQRCode="isAllowQRCode"
                                  v-on:onUpdate="onUpdatePayment"
                                  v-on:receive-payment="onUpdatePayment"
                                  v-on:receive-payment-mul="onUpdatePaymentV2"
                                  v-on:click-generate-qrcode="onClickGenerateQR"
                                />
                              </b-col>
                            </b-row>
                            <b-row
                              v-if="mainModel.id"
                              class="col-md-12 mb-1 m-0 p-0"
                            >
                              <b-container
                                class="p-0"
                                v-if="checkPermission('TRANSACTION_VIEW')"
                              >
                                <hr
                                  class="hr-text"
                                  data-content="Bút Toán"
                                  style="font-weight: 600"
                                />
                                <b-row>
                                  <b-col
                                    md="12"
                                    lg="12"
                                    sm="12"
                                  >
                                    <BillTransactions :billNumber="$route.query.id" />
                                  </b-col>
                                </b-row>
                              </b-container>
                              <b-container
                                class="p-0"
                                v-if="checkPermission('STOCK_VIEW')"
                              >
                                <hr
                                  class="hr-text"
                                  data-content="Phiếu bảo hành"
                                  style="font-weight: 600"
                                />
                                <b-row>
                                  <b-col md="12" lg="12" sm="12">
                                    <WarrantyTransaction
                                      :billNumber="mainModel.id"
                                    />
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-row>
                            <div style="position: sticky; top: 200px">
                              <b-row>
                                <b-col>
                                  <b-button
                                    style="
                                      fontweight: 600;
                                      width: 70px;
                                      margin-right: 10px;
                                    "
                                    variant="primary"
                                    size="sm"
                                    @click="onSubmit('save')"
                                    v-if="mainModel.editable"
                                    :disabled="onSubmiting"
                                  >Lưu</b-button>
                                  <b-button
                                    style="
                                      margin-right: 10px;
                                      fontweight: 600;
                                      width: 100px;
                                    "
                                    variant="warning"
                                    size="sm"
                                    @click="onSubmit('save-print')"
                                    :disabled="onSubmiting"
                                    v-if="mainModel.editable && mainModel.id"
                                  >
                                    Lưu và in
                                  </b-button>
                                  <router-link
                                    to="/wholesaleInvoices"
                                    tag="button"
                                  >
                                    <b-button
                                      tabindex="16"
                                      style="font-weight: 600; width: 70px"
                                      variant="secondary"
                                      size="sm"
                                    >Hủy</b-button>
                                  </router-link>
                                </b-col>
                              </b-row>
                            </div>
                          </b-form>
                        </div>

                        <div
                          class="py-4 mb-5 full-width"
                          data-wizard-type="step-content"
                        >
                          <b-container class="bv-example-row">
                            <b-table
                              :fields="historyFields"
                              :items="histories"
                              class="table-bordered table-hover col-md-6"
                            >
                              <template v-slot:cell(updatedBy)="row">
                                <p>{{ row.item.updatedBy }}</p>
                                <p>{{ row.item.updatedAt }}</p>
                              </template>
                              <template v-slot:cell(action)="row">
                                <p>
                                  {{
                                    row.item.action === 1
                                      ? 'Sửa hóa đơn'
                                      : 'Xóa hóa đơn'
                                  }}
                                </p>
                              </template>
                              <template v-slot:cell(detail)="row">
                                <div
                                  class="orderCode"
                                  @click="viewHistoryDetail(row.item)"
                                  style="cursor: pointer; color: #3699ff"
                                >
                                  <span v-text="row.item.id"></span>
                                </div>
                              </template>
                            </b-table>
                          </b-container>
                        </div>

                        <div
                          class="py-4 mb-5 full-width"
                          data-wizard-type="step-content"
                        >
                          <div class="upload-file col-12">
                            <UploadFile
                              v-if="mainModel.billNumber"
                              :id="mainModel.billNumber"
                              :entity="'bills'"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-skeleton-wrapper>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
        <BillGiftModal
          ref="bill-gift-modal"
          :billItemId="selectedBillItem.billItemId"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          v-on:addGift="addGift"
        />
        <BillAttachModal
          ref="bill-attach-modal"
          :billItemId="selectedBillItem.billItemId"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          :attachItems="selectedBillItem.attachItems"
          v-on:addAttach="addAttach"
        />
        <BillWarrantyModal
          ref="bill-warranty-modal"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          :productId="selectedBillItem.productId"
          v-on:addWarranty="addWarranty"
        />
        <ChangeGiftModal
          ref="change-gift-modal"
          :productId="selectedBillItem.productId"
          :listProductVariant="selectedBillItem.listProductVariant"
          v-on:changeGift="onChangeGift"
        />
        <BillNoteModal
          ref="bill-note-modal"
          :productName="selectedBillItem.productName"
          :note="selectedBillItem.note"
          v-on:addNote="onChangeNote"
        />
        <ImeiListByProduct
          ref="list-imei-modal"
          :billItemId="selectedBillItem.billItemId"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          :productId="selectedBillItem.productId"
          v-on:applyImei="applyImei"
        />
        <UpsertShiftWorkConfigModal
          v-on:fetchShiftWorkConfig="fetchShiftWorkConfig"
          ref="modal-upsert-shiftWork"
          :shiftWorkConfig="shiftWorkConfigSelected"
        />

        <ActiveImei ref="active-imei-modal" />
        <QRCodeModal
          ref="qr-code-modal"
          :qrCode="qrCode"
          :billNumber="mainModel.billNumber"
          :type="REFERENCE_TYPE.BILL"
          :transferAmount="transferAmount"
          :payment="qrCodePaymentInfo"
          @onGenerateQR="onGenerateQR"
          @afterGenerateQR="afterGenerateQR"
        />
        <loading
          :active.sync="isLoading"
          :is-full-page="fullPage"
        ></loading>
        <FlexibleComboModal
          ref="flexible-combo"
          :flexibleComboItem="flexibleComboItem"
          :storeId="mainModel.storeId"
          v-on:addComboChild="addComboChild"
        />
        <ImportSaleProduct
          ref="product-sale-excel"
          :storeId="mainModel.storeId"
          v-on:validData="validDataImport"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  convertPrice,
  makeToastFaile,
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
  makeToastFailHtml,
} from '@/utils/common';
import decounce from 'debounce';
import { v4 } from 'uuid';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { TIME_TRIGGER } from '@/utils/constants';
import SearchStore from '../../components/stores/SearchStore.vue';
import CustomerInfo from '../../components/customers/CustomerInfo.vue';
import EmployeeInfo from '../../components/bills/EmployeeInfo.vue';
import WhosesaleContractInfo from '../../components/bills/WhosesaleContractInfo.vue';
import BillPayment from '../../components/bills/BillPayment.vue';
import BillNote from '../../components/bills/BillNote.vue';
import BillGiftModal from '../../components/bills/BillGiftModal';
import BillAttachModal from '../../components/bills/BillAttachModal';
import BillWarrantyModal from '../../components/bills/BillWarrantyModal';
import ChangeGiftModal from '../../components/bills/ChangeGiftModal';
import BillNoteModal from '../../components/bills/BillNoteModal';
import ImeiListByProduct from '../../components/bills/ImeiListByProduct';
import UpsertShiftWorkConfigModal from '@/view/components/shiftWorkConfig/UpsertShiftWorkConfigModal';
import BillItem from '../../components/BillItem.vue';
import {
  AMOUNT_TYPE,
  BILL_ITEM_TYPE,
  PRODUCT_SEARCH_TYPE,
  PRODUCT_TYPE,
  PAYMENT_TYPE,
  PAYMENT_STATUS,
  REFERENCE_TYPE,
} from '@/utils/enum';

import BillTransactions from '../../components/BillTransactions.vue';
import ActiveImei from '../../components/imeis/ActiveImei';
import WarrantyTransaction from '../../components/WarrantyTransaction.vue';
import localData from '@/utils/saveDataToLocal';
import { cloneDeep, sortBy, uniq } from 'lodash';
import { mapGetters } from 'vuex';
import {
  PURGE_INTEREST_ZERO,
  PURGE_INSTALLMENT_PROGRAM,
  SET_INSTALLMENT_PROGRAM,
  SET_INTEREST,
} from '@/core/services/store/modules/payment/payment-installment.module';
import { EventBus } from '@/core/services/event-bus';
import QRCodeModal from '../../components/bills/QRCodeModal.vue';
import db from '../../../firebase/init';
import { deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import FlexibleComboModal from '../../components/bills/FlexibleComboModal';
import UploadFile from '@/view/modules/upload/Upload';
import ImportSaleProduct from '../../components/bills/ImportSaleProduct';
import BillSkeleton from '../../components/bills/BillSkeleton';

const BillVatInfo = () =>
  import(/* webpackPrefetch: true */ '@/view/components/bills/BillVatInfo.vue');

export default {
  mixins: [validationMixin],
  data() {
    return {
      isAllowQRCode: false,
      shiftWorkConfigSelected: null,
      shiftWorkConfig: null,
      qrCodePaymentInfo: {},
      REFERENCE_TYPE,
      isLoading: false,
      fullPage: true,
      qrCode: {
        image: '',
        code: '',
      },
      productLoading: false,
      listTypeSearch: [
        {
          id: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
          name: 'Tìm sản phẩm',
        },
        {
          id: PRODUCT_SEARCH_TYPE.IMEI_CODE,
          name: 'Tìm sản phẩm IMEI',
        },
        {
          id: PRODUCT_SEARCH_TYPE.FLEXIBLE_COMBO,
          name: 'Tìm bộ combo',
        },
      ],
      selectTypeSearch: 1,
      filteredOptionsProduct: [{ data: [] }],
      optionsProduct: [],
      searchProduct: '',
      isSearching: false,
      searchStore: '',
      inputSearchProductProp: {
        class: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        disabled: true,
      },
      mainModel: {
        id: null,
        billNumber: null,
        storeId: null,
        storeName: '',
        siteCode: '',
        discountType: 1,
        discountAmount: 0,
        couponCode: '',
        isCountPoint: false,
        subtractPoint: 0,
        otpCode: '',
        isSubtractPointValidated: false,
        moneyTransfered: 0,
        moneyPaid: 0,
        moneyCredit: 0,
        moneyInstallment: 0,
        totalAmountPoint: 0,
        customerShipFee: 0,
        codeShip: '',
        customerModel: {
          customerId: null,
          customerMobile: '',
          customerName: '',
          customerLastName: '',
          customerAddress: '',
          customerCity: null,
          customerDistrict: null,
          customerWard: null,
          totalPoint: 0,
        },
        warrantyCustomerModel: {
          customerId: null,
          customerMobile: '',
          customerName: '',
          customerLastName: '',
        },
        billEmployeeInfo: {
          saleId: null,
          saleName: '',
          technicalId: null,
          technicalName: '',
          cashierId: null,
          cashierName: '',
          accessoryStaffId: null,
          accessoryStaffName: '',
          managerId: null,
          managerName: '',
          assistantId: null,
          assistantName: '',
          customerReceptionId: null,
          customerReceptionName: '',
          shipperId: null,
          shipperName: '',
        },
        companyBill: false,
        vatInvoice: {
          vatCompanyType: 1,
          taxCode: '',
          companyAddress: '',
          companyName: '',
          email: '',
        },
        paymentInfo: {
          //tiền mặt
          cashAmount: 0,
          cashAccountantName: '',
          cashAccountId: null,
          //chuyển khoản
          transferAmount: 0,
          transferAccountantName: '',
          transferAccountantId: null,
          transferReferenceCode: '',
          //quẹt thẻ
          creditAmount: 0,
          creditAccountantName: '',
          creditAccountantId: null,
          creditCode: '',
          creditCardNo: '',
          //phí quẹt thẻ
          creditCardFee: 0,
          creditFeeAccountantName: '',
          creditFeeAccountantId: null,
          payCreditFeeType: 1,
          //trả góp
          installedMoneyAmount: 0,
          installmentCode: '',
          installmentAccountName: '',
          installMoneyAccountId: null,
          customerIndentifyNo: '',
          installmentProgramMonthNo: 0,
        },
        billNote: {
          customerNote: '',
          saleNote: '',
          warrantyNote: '',
        },
        orderSourceId: null,
        editable: true,
        fromBillNumber: null,
        storeDisable: false,
        installments: [],
        credits: [],
        transfer: [],
        cash: [],
        refunds: [],
        whosesaleContractModel: {
          contractCode: '',
          contractSigningDate: '',
        },
      },
      listBillItem: [],
      listAmountType: [
        {
          id: AMOUNT_TYPE.MONEY,
          name: '$',
        },
        {
          id: AMOUNT_TYPE.PERCENT,
          name: '%',
        },
      ],
      mask: currencyMask,
      selectedBillItem: {
        billItemId: null,
        productName: '',
        attachItems: [],
        listProductVariant: [],
        note: '',
      },
      isValidImei: true,
      onSubmiting: false,
      listCustomerSource: [],
      hashName: null,
      histories: [],
      historyFields: [
        {
          key: 'updatedBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'action',
          label: 'Hành động',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
        },
        {
          key: 'detail',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
        },
      ],
      renderComponent: false,
      pageTitle: null,
      onPaymentSubmiting: false,
      orderNotComplete: null,
      isValidTaxCode: true,
      isValidEmail: true,
      transferAmount: 0,
      flexibleComboItem: {
        id: null,
        name: '',
        code: '',
        discountAmount: 0,
        discountType: AMOUNT_TYPE.MONEY,
        listItem: [],
      },
    };
  },
  validations: {
    searchStore: {
      required,
    },
  },
  components: {
    Loading,
    KTCodePreview,
    SearchStore,
    BillItem,
    CustomerInfo,
    EmployeeInfo,
    WhosesaleContractInfo,
    BillVatInfo,
    BillPayment,
    BillNote,
    BillGiftModal,
    BillAttachModal,
    BillWarrantyModal,
    ChangeGiftModal,
    BillNoteModal,
    ImeiListByProduct,
    BillTransactions,
    WarrantyTransaction,
    UploadFile,
    ActiveImei,
    QRCodeModal,
    UpsertShiftWorkConfigModal,
    FlexibleComboModal,
    ImportSaleProduct,
    BillSkeleton,
  },
  destroyed() {
    this.$store.dispatch(PURGE_INTEREST_ZERO);
    this.$store.dispatch(PURGE_INSTALLMENT_PROGRAM);
  },
  watch: {
    getInstallmentInterestZero: {
      handler(value) {
        this.fetchProgramByProductIds(value);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getInstallmentInterestZero', 'getInstallmentProgram']),
    totalAmountBeforeDiscount() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          total += totalPrice;
        } else if (
          element.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS &&
          element.checked === false
        ) {
          total -=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        } else if (
          element.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION &&
          element.checked === true
        ) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      return total;
    },
    totalAmount() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.quantity != 0) {
          if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) {
            const totalPrice =
              unMaskPrice(element.price) * unMaskPrice(element.quantity);
            // Check discount type
            if (element.discountType == AMOUNT_TYPE.PERCENT) {
              const discountAmount = totalPrice * element.discount;
              total += totalPrice - (discountAmount / 100).toFixed(0);
            } else {
              total += totalPrice - unMaskPrice(element.discount);
            }
          } else if (
            element.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS &&
            element.checked === false
          ) {
            total -=
              unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
          } else if (
            element.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION &&
            element.checked === true
          ) {
            total +=
              unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
          }
        }
      }
      const discountAmount = this.mainModel.discountAmount
        ? unMaskPrice(this.mainModel.discountAmount)
        : 0;
      if (this.mainModel.discountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      return total || 0;
    },
    totalAmountReturn() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (
          element.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS &&
          element.checked === false
        ) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      return total;
    },
    finalAmount() {
      if (!this.totalAmount) {
        return 0;
      }
      const final =
        this.totalAmount -
        this.mainModel.moneyTransfered -
        this.mainModel.moneyPaid -
        this.mainModel.moneyCredit -
        this.mainModel.moneyInstallment -
        this.mainModel.totalAmountPoint +
        this.mainModel.customerShipFee;

      return final || 0;
    },
    totalPaid() {
      return (
        this.mainModel.moneyPaid +
        this.mainModel.moneyTransfered +
        this.mainModel.moneyCredit +
        this.mainModel.moneyInstallment
      );
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn bán buôn', route: '/wholesaleInvoices' },
      { title: 'Danh sách hóa đơn bán buôn', route: '/wholesaleInvoices' },
      { title: 'Thông tin hóa đơn bán buôn' },
    ]);
    this.getListOrderSource();
    this.mainModel.orderId = this.$route.query.fromOrderId
      ? this.$route.query.fromOrderId
      : null;
    this.mainModel.billNumber = this.$route.query.id
      ? this.$route.query.id
      : null;
    if (this.mainModel.billNumber) {
      if (this.$route.name === 'add-wholesale-invoice') {
        this.mainModel.fromBillNumber = this.$route.query.id;
      } else {
        this.isAllowQRCode = true;
      }
      this.getBillById();
      this.fetchBillHistory();
    } else {
      if (this.mainModel.orderId) {
        this.getOrderById();
      } else {
        this.renderComponent = true;
      }
    }

    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4_bill', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function(/*wizardObj*/) {});

    wizard.on('change', function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, TIME_TRIGGER);
    });

    this.fetchStoreByUser();
    await this.getPaymentResult();
  },
  methods: {
    convertPrice,
    onClickGenerateQR(payment) {
      if (payment.status === PAYMENT_STATUS.SUCCESS) {
        return makeToastFaile('Vui lòng tạo thanh toán mới!');
      }
      this.qrCodePaymentInfo = payment;
      if (payment.viaQRCode) {
        this.transferAmount = payment.paymentAmount;
        setTimeout(() => {
          this.$refs['qr-code-modal'].generateQRCode();
        }, TIME_TRIGGER);
      }
    },
    onGenerateQR() {
      this.isLoading = true;
    },
    afterGenerateQR() {
      this.isLoading = false;
    },
    async getPaymentResult() {
      const VERIFY_COLLECTION = 'payment-verify-results';
      onSnapshot(
        doc(db, VERIFY_COLLECTION, String(this.mainModel.billNumber)),
        (snap) => {
          if (snap.exists()) {
            const snapData = snap.data();
            if (
              String(snapData.referenceNumber) ===
              String(this.mainModel.billNumber)
            ) {
              //  remove doc
              deleteDoc(
                doc(db, VERIFY_COLLECTION, String(this.mainModel.billNumber)),
              );
              this.$refs['qr-code-modal'].hideModal();
              Swal.fire(
                'Thành công',
                'Bạn đã thanh toán thành công!',
                'success',
              ).then(() => {
                this.$router.go(this.$router.currentRoute);
              });
            }
          }
        },
      );
    },
    onShiftWorkConfigSelected() {
      for (const key in this.mainModel.billEmployeeInfo) {
        if (!this.mainModel.billEmployeeInfo[key]) {
          if (key != 'saleId' && key != 'saleName') {
            this.mainModel.billEmployeeInfo[key] = this.shiftWorkConfig[key];
          }
        }
      }
    },
    resetEmployData() {
      for (const key in this.mainModel.billEmployeeInfo) {
        if (key != 'saleId' && key != 'saleName') {
          if (key.includes('Id')) {
            this.mainModel.billEmployeeInfo[key] = null;
          } else if (key.includes('Name')) {
            this.mainModel.billEmployeeInfo[key] = '';
          }
        }
      }
    },
    linkToOrder: function(item) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: item },
      });
      window.open(href, '_blank');
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    async onSelectedProduct(option) {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.FLEXIBLE_COMBO) {
        this.flexibleComboItem.name = option.item.productName;
        this.flexibleComboItem.id = option.item.productId;
        this.flexibleComboItem.code = option.item.productCode;
        this.flexibleComboItem.discountAmount = option.item.discountAmount;
        this.flexibleComboItem.discountType = option.item.discountType;
        this.flexibleComboItem.listItem = option.item.listItem;
        this.showModalFlexibleCombo();
        return;
      }

      this.searchProduct = '';
      const selectedProduct = option.item;
      let imeiCode = '';
      let productType = selectedProduct.productType;

      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        imeiCode = selectedProduct.imeiCode;
        productType = PRODUCT_TYPE.PRODUCT_IMEI;
      }
      const isInterestZero = this.getInstallmentInterestZero;
      const programPrice = isInterestZero
        ? option.item.listedPrice
        : option.item.sellingPrice;

      const billItem = {
        id: v4(),
        stt: 0,
        productId: selectedProduct.productId,
        name: selectedProduct.productName,
        productNameVat: selectedProduct.productNameVat,
        code: selectedProduct.productCode,
        price: programPrice,
        type: productType,
        quantity: 1,
        imeiCode: imeiCode,
        discount: 0,
        quantityInStock: selectedProduct.quantityInStock,
        storeId: this.mainModel.storeId,
        comboItems: selectedProduct.listProductInCombo,
        billItemType: BILL_ITEM_TYPE.PRODUCT,
        checked: true,
        listAccessoryPromotion: selectedProduct.listAccessoryPromotion,
        returnPrice: 0,
        accessoryGroupId: '',
        accessoryGroupCode: '',
        note: '',
        discountType: AMOUNT_TYPE.PERCENT,
        discountProgramId: -1,
        isGetVat: false,
        sellingPrice: programPrice,
        listedPrice: selectedProduct.listedPrice,
        merchantId: selectedProduct.merchantId || 1,
        // ...installmentProgramModel,
      };
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        if (this.isExistImeiCode(this.listBillItem, imeiCode)) {
          return makeToastFaile('Mã IMEI đã tồn tại trong hóa đơn');
        }
        this.listBillItem.push(billItem);
        await this.fetchProductAttach(selectedProduct.productId, billItem.id);
      } else {
        if (
          selectedProduct.productType === PRODUCT_TYPE.PRODUCT_SERVICE ||
          selectedProduct.quantityInStock > 0
        ) {
          this.listBillItem.push(billItem);
          await this.fetchProductAttach(selectedProduct.productId, billItem.id);
        } else {
          makeToastFaile('Số sản phẩm tồn trong kho không đủ!');
        }
      }

      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.optionsProduct;
    },
    onInputChangProduct(text) {
      this.optionsProduct;
      this.filteredOptionsProduct = [
        {
          data: this.optionsProduct,
        },
      ];
      if (!text) {
        text = '';
        this.productLoading = false;
        return;
      }
      this.productLoading = true;
      this.searchProduct = text.trim();
      this.debounceInputProduct();
    },
    debounceInputProduct: decounce(function() {
      if (this.searchProduct) {
        this.searchProductAPI();
      }
    }, TIME_TRIGGER),
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct ? this.searchProduct.trim() : '';
        if (!textSearch) {
          return;
        }
        if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
          this.fetchProduct(textSearch);
        } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
          this.fetchProductImei(textSearch);
        } else if (
          this.selectTypeSearch === PRODUCT_SEARCH_TYPE.FLEXIBLE_COMBO
        ) {
          this.searchFlexibleCombo(textSearch);
        }
      }
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.optionsProduct = [];
      const params = {
        storeId: this.mainModel.storeId,
        searchProduct: textSearch,
        isInterestZero: this.getInstallmentInterestZero,
      };

      const url = params.isInterestZero
        ? 'productSearch/search'
        : 'productSearch';

      ApiService.query(url, {
        params,
      }).then(({ data }) => {
        const products = data.data;
        this.optionsProduct = products.map((element) => {
          const sellingPrice =
            element.wholesalePrice && element.wholesalePrice !== 0
              ? element.wholesalePrice
              : element.sellingPrice;
          return {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            productNameVat: element.productNameVat,
            barCode: element.barCode,
            quantityInStock: element.quantityInStock
              ? element.quantityInStock
              : 0,
            sellingPrice: sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            listedPrice: element.listedPrice || 0,
            merchantId: element.merchantId || 1,
          };
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct,
          },
        ];
        this.isSearching = false;
        this.productLoading = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.optionsProduct = [];
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.mainModel.storeId}&imeiCode=${textSearch}`,
      ).then(({ data }) => {
        const products = data.data;
        this.optionsProduct = products.map((element) => {
          const sellingPrice =
            element.wholesalePrice && element.wholesalePrice !== 0
              ? element.wholesalePrice
              : element.sellingPrice;
          return {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            productNameVat: element.productNameVat,
            sellingPrice: sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            productType: element.productType,
            imeiCode: element.imeiCode,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            quantityInStock: element.quantityInStock
              ? element.quantityInStock
              : 0,
            listedPrice: element.listedPrice,
            merchantId: element.merchantId || 1,
          };
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct,
          },
        ];
        this.isSearching = false;
        this.productLoading = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    changePlaceholder() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo mã IMEI sản phẩm';
      }
      this.optionsProduct = [];
      this.debounceInputProduct();
    },
    async fetchShiftWorkConfig() {
      ApiService.setHeader();

      if (this.mainModel.storeId) {
        const params = {
          storeId: this.mainModel.storeId,
          billCreatedAt: this.mainModel.billCreatedAt
            ? this.mainModel.billCreatedAt
            : null,
        };
        ApiService.query('shift-work-config/getByStore', { params })
          .then(({ data }) => {
            if (data.status === 1) {
              this.shiftWorkConfig = data.data;
            } else {
              makeToastFaile(data.message);
            }
          })
          .catch(({ response }) => {
            makeToastFaile(response.$error);
          });
      }
    },
    onStoreSelected(storeId) {
      this.mainModel.storeId = storeId;
      this.resetEmployData();
      this.fetchShiftWorkConfig();
      this.inputSearchProductProp.disabled = false;
      this.listBillItem = [];
    },
    isExistImeiCode(listProduct, imeiCode) {
      const existed = listProduct.find((x) => x.imeiCode === imeiCode);
      return existed ? true : false;
    },
    async checkInsProgramByProductId(productId) {
      const params = {
        productId: productId,
      };

      return ApiService.query(`installment-programs/product`, {
        params,
      }).then(({ data }) => {
        const product = data.data;
        return product;
      });
    },
    showWarning(text) {
      return Swal.fire({
        title: 'Trả góp 0%!',
        text: `${text}`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(221, 51, 51)',
        confirmButtonText: 'Đồng ý',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
    sortListBillItem(listBillItem) {
      const listResult = [];
      const mainItems = listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT,
      );
      const attachItems = listBillItem.filter(
        (item) => item.billItemType !== BILL_ITEM_TYPE.PRODUCT,
      );
      mainItems.forEach((billItem, index) => {
        billItem.stt = index + 1;
        listResult.push(billItem);
        let belongBillItems = attachItems.filter(
          (item) => item.belongBillDetailId === billItem.id,
        );
        belongBillItems = sortBy(belongBillItems, ['billItemType']);
        belongBillItems.forEach((attachItem) => {
          listResult.push(attachItem);
        });
      });
      return listResult;
    },
    async fetchProductAttach(productId, parentId) {
      ApiService.get(
        `productSearch/get-product-attach?storeId=${this.mainModel.storeId}&productId=${productId}`,
      ).then(({ data }) => {
        //response data
        const item = {
          productId: data.data.productId,
          productName: data.data.productName,
          productNameVat: data.data.productNameVat,
          listAccessoryBonus: data.data.listAccessoryBonus,
          listAccessoryPromotion: data.data.listAccessoryPromotion,
        };
        //mapping data to main item
        const billItem = this.listBillItem.find((item) => item.id === parentId);
        if (!billItem) {
          return;
        }
        //gift
        billItem.listAccessoryBonus = item.listAccessoryBonus;
        item.listAccessoryBonus.forEach((element) => {
          const code = `Quà tặng kèm- ${element.accessoryGroupCode}`;

          const giftItem = {
            id: v4(),
            productId: element.productId,
            name: element.productName,
            productNameVat: element.productNameVat,
            code: code,
            price: element.sellingPrice,
            quantity: 1,
            type: element.productType,
            imeiCode: '',
            totalPrice: element.sellingPrice,
            discount: '',
            quantityInStock: element.quantityInStock,
            storeId: this.selectedClassStore,
            billItemType: BILL_ITEM_TYPE.PRODUCT_BONUS,
            checked: true,
            belongBillDetailId: parentId,
            returnPrice: element.price,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            listProductVariant: element.listProductVariant,
            isGetVat: false,
          };
          this.listBillItem.push(giftItem);
        });

        //attach
        billItem.listAccessoryPromotion = item.listAccessoryPromotion;

        this.listBillItem = this.sortListBillItem(this.listBillItem);
      });
    },
    deleteBillItem(id) {
      this.listBillItem = this.listBillItem.filter(
        (billItem) => billItem.id !== id && billItem.belongBillDetailId !== id,
      );

      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    showGiftModal(billItemId) {
      this.selectedBillItem.billItemId = billItemId;
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      setTimeout(() => {
        this.$refs['bill-gift-modal'].showModal();
      }, TIME_TRIGGER);
    },
    addGift(gifts) {
      this.listBillItem = this.listBillItem.concat(gifts);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    showAttachModal(billItemId) {
      this.selectedBillItem.billItemId = billItemId;
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.attachItems = billItem
        ? billItem.listAccessoryPromotion
        : [];
      setTimeout(() => {
        this.$refs['bill-attach-modal'].showModal();
      }, TIME_TRIGGER);
    },
    addAttach(attachs) {
      this.listBillItem = this.listBillItem.concat(attachs);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    showWarrantyModal(productId) {
      const billItem = this.listBillItem.find(
        (item) => item.productId === productId,
      );
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.productId = productId;
      setTimeout(() => {
        this.$refs['bill-warranty-modal'].showModal();
      }, TIME_TRIGGER);
    },
    addWarranty(warrantyItems) {
      const listItem = warrantyItems.map((item) => {
        return {
          id: v4(),
          stt: 0,
          productId: item.id,
          name: item.productName,
          productNameVat: '',
          code: item.productCode,
          price: item.sellingPrice,
          type: 4,
          quantity: 1,
          imeiCode: '',
          discount: 0,
          quantityInStock: 0,
          storeId: this.mainModel.storeId,
          comboItems: [],
          billItemType: BILL_ITEM_TYPE.PRODUCT,
          checked: true,
          listAccessoryPromotion: [],
          returnPrice: 0,
          accessoryGroupId: '',
          accessoryGroupCode: '',
          note: '',
          discountType: AMOUNT_TYPE.PERCENT,
          discountProgramId: -1,
          isGetVat: false,
          sellingPrice: item.sellingPrice,
        };
      });
      this.listBillItem = this.listBillItem.concat(listItem);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].productId = item.productId;
          this.listBillItem[index].price = item.price
            ? unMaskPrice(item.price)
            : 0;
          this.listBillItem[index].quantity = item.quantity
            ? unMaskPrice(item.quantity)
            : 0;
          this.listBillItem[index].discount = item.discount
            ? unMaskPrice(item.discount)
            : 0;
          this.listBillItem[index].note = item.note;
          this.listBillItem[index].discountType = item.discountType;
          this.listBillItem[index].discountProgramId = item.discountProgramId;
        }
      });
    },
    showChangeGiftModal(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.listProductVariant = billItem
        ? billItem.listProductVariant
        : [];
      this.selectedBillItem.productId = billItem ? billItem.productId : null;
      this.selectedBillItem.billItemId = billItem.id;
      setTimeout(() => {
        this.$refs['change-gift-modal'].showModal();
      }, TIME_TRIGGER);
    },
    onChangeGift(productId) {
      const productSelected = this.selectedBillItem.listProductVariant.find(
        (variant) => variant.productId === productId,
      );
      const billItem = this.listBillItem.find(
        (item) => item.id === this.selectedBillItem.billItemId,
      );
      billItem.productId = productSelected.productId;
      billItem.name = productSelected.productName;
      billItem.productNameVat = productSelected.productNameVat;
    },
    showNoteModal(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.note = billItem ? billItem.note : '';
      this.selectedBillItem.billItemId = billItemId;
      setTimeout(() => {
        this.$refs['bill-note-modal'].showModal();
      }, TIME_TRIGGER);
    },
    onChangeNote(note) {
      const billItem = this.listBillItem.find(
        (item) => item.id === this.selectedBillItem.billItemId,
      );
      billItem.note = note;
    },
    showShiftWorkConfigModal() {
      this.shiftWorkConfigSelected = {
        ...this.shiftWorkConfig,
        storeName: this.mainModel.storeName,
      };
    },
    showListImeiModal(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.productId = billItem ? billItem.productId : null;
      this.selectedBillItem.billItemId = billItem.id;
      setTimeout(() => {
        this.$refs['list-imei-modal'].showModal(
          this.selectedBillItem.productId,
        );
      }, TIME_TRIGGER);
    },
    applyImei(imeiCode) {
      const billItem = this.listBillItem.find(
        (item) => item.id === this.selectedBillItem.billItemId,
      );
      billItem.imeiCode = imeiCode;
    },
    viewInventory(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      if (billItem) {
        let nameRouter = '';
        if (billItem.type === 2) {
          nameRouter = 'inventory-detail-imei';
        } else {
          nameRouter = 'inventory-detail';
        }
        const { href } = this.$router.resolve({
          name: nameRouter,
          query: {
            id: billItem.productId,
          },
        });
        window.open(href, '_blank');
      }
    },
    isValidImeiCode(listBillItem) {
      let check = true;
      for (let index = 0; index < listBillItem.length; index++) {
        const element = listBillItem[index];
        if (
          element.type === PRODUCT_TYPE.PRODUCT_IMEI &&
          element.checked === true
        ) {
          if (!element.imeiCode || !element.imeiCode.trim()) {
            check = false;
            break;
          }
        }
      }
      return check;
    },
    onSubmit: async function(mode = 'save') {
      this.onSubmiting = true;

      const listProductsHaveZeroQuantity = this.listBillItem.filter(
        (item) => Number(item.quantity) === 0,
      );
      if (listProductsHaveZeroQuantity.length) {
        this.onSubmiting = false;
        return makeToastFaile('Số lượng các sản phẩm phải lớn hơn 0');
      }

      if (!this.mainModel.storeId) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng chọn cửa hàng!');
      }

      if (!this.listBillItem.length) {
        this.onSubmiting = false;
        return makeToastFaile('Bạn chưa chọn sản phẩm nào');
      }
      if (!this.isValidImei) {
        this.onSubmiting = false;
        //kt lại 1 lần nữa xem IMEI có hợp lệ ko
        makeToastFaile('Mã IMEI không hợp lệ để bán!');
        this.isValidImei = true;
        return;
      }
      if (!this.isValidImeiCode(this.listBillItem)) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng nhập IMEI cho sản phẩm IMEI');
      }

      const billData = this.setBillData();

      // check creditFeeAmount
      if (billData.creditAmount === 0) {
        billData.otherFees = 0;
      }

      if (billData.isInterestZero && billData.listBillItem.length > 1) {
        this.onSubmiting = false;
        return makeToastFaile(
          'Chương trình trả góp 0% chỉ áp dụng cho 1 sản phẩm',
        );
      }

      this.onPaymentSubmiting = false;
      const errorPayments = this.validatePayment(billData);

      if (errorPayments.length) {
        this.onPaymentSubmiting = true;
        this.onSubmiting = false;
        return makeToastFailHtml(this, errorPayments);
      }

      if (!billData.customerMobile) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng nhập thông tin khách hàng hợp lệ!');
      }

      if (!this.isValidTaxCode) {
        this.onSubmiting = false;
        return makeToastFaile('Mã số thuế không hợp lệ!');
      }

      if (!this.isValidEmail) {
        this.onSubmiting = false;
        return makeToastFaile('Email không hợp lệ!');
      }

      if (this.totalAmountBeforeDiscount < billData.discountAmount) {
        this.onSubmiting = false;
        return makeToastFaile(
          'Số tiền chiết khấu không nên cao hơn số tiền sản phẩm',
        );
      }
      if (!this.mainModel.id) {
        if (!this.mainModel.orderId && this.orderNotComplete) {
          await this.showWarningOrderNotComplete(billData);
        } else {
          this.onCallApiCreate(billData);
        }
      } else {
        this.onCallApiUpdate(billData, mode);
      }
    },
    onCallApiCreate: async function(billData) {
      const url = this.mainModel.fromBillNumber
        ? `bills/wholesale-bill/re-create/${this.mainModel.fromBillNumber}`
        : 'bills/wholesale-bill/v2';
      await ApiService.post(url, billData)
        .then(async ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.mainModel.billNumber = data.data.billNumber;
            this.$router.push({
              path: '/wholesaleInvoices',
            });
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
    onCallApiUpdate: async function(billData, mode = 'save') {
      await ApiService.put('bills/wholesale-bill/v2', billData)
        .then(async ({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            if (mode === 'save-print') {
              this.$router.push({
                name: 'print-bill',
                query: { id: billData.billNumber },
              });
            } else {
              this.$router.push({
                path: '/wholesaleInvoices',
              });
            }
            this.onSubmiting = false;
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile(err.$error);
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.onSubmiting = false;
        });
    },
    setBillData() {
      const billItems = this.formatListBillItem();
      this.transferAmount = this.calcPaymentsAmount(this.mainModel.transfer);
      return {
        id: this.mainModel.id,
        billNumber: this.mainModel.billNumber || null,
        storeId: this.mainModel.storeId,
        //khach hang
        customerMobile: this.mainModel.customerModel.customerMobile.trim(),
        customerName: this.mainModel.customerModel.customerName.trim(),
        customerAddress: this.mainModel.customerModel.customerAddress,
        customerCity: this.mainModel.customerModel.customerCity,
        customerId: this.mainModel.customerModel.customerId,
        customerDistrict: this.mainModel.customerModel.customerDistrict,
        customerWard: this.mainModel.customerModel.customerWard,
        customerLastName: this.mainModel.customerModel.customerLastName,
        orderSourceId: this.mainModel.orderSourceId,
        //khach hang bao hanh
        warrantyCustomerId: this.mainModel.warrantyCustomerModel.customerId,
        warrantyCustomerName: this.mainModel.warrantyCustomerModel.customerName,
        warrantyCustomerPhone: this.mainModel.warrantyCustomerModel
          .customerMobile,
        warrantyCustomerLastName: this.mainModel.warrantyCustomerModel
          .customerLastName,
        //nhan vien
        saleId: this.mainModel.billEmployeeInfo.saleId,
        technicalId: this.mainModel.billEmployeeInfo.technicalId,
        accessoryStaffId: this.mainModel.billEmployeeInfo.accessoryStaffId,
        cashierId: this.mainModel.billEmployeeInfo.cashierId,
        managerId: this.mainModel.billEmployeeInfo.managerId,
        assistantId: this.mainModel.billEmployeeInfo.assistantId,
        customerReceptionId: this.mainModel.billEmployeeInfo
          .customerReceptionId,
        shipperId: this.mainModel.billEmployeeInfo.shipperId,
        //ghi chu
        customerNote: this.mainModel.billNote.customerNote,
        saleNote: this.mainModel.billNote.saleNote,
        warrantyNote: this.mainModel.billNote.warrantyNote,
        //chiet khau
        discountType: this.mainModel.discountType,
        discountAmount: this.mainModel.discountAmount
          ? unMaskPrice(this.mainModel.discountAmount)
          : 0,
        listBillItem: billItems,
        orderId: this.mainModel.orderId,
        //hoa don do
        vatCompanyName: this.mainModel.vatInvoice.companyName,
        vatCompanyAddress: this.mainModel.vatInvoice.companyAddress,
        vatCompanyType: this.mainModel.vatInvoice.vatCompanyType,
        email: this.mainModel.vatInvoice.email,
        taxCode: this.mainModel.vatInvoice.taxCode,
        //tieu diem
        pointUse: this.mainModel.subtractPoint
          ? unMaskPrice(this.mainModel.subtractPoint)
          : 0,
        isSubtractPointValidated: this.mainModel.isSubtractPointValidated,
        isCountPoint: this.mainModel.isCountPoint,
        couponCode: this.mainModel.couponCode,
        //chuong trinh tra gop
        installmentProgramId: this.getInstallmentProgram.installmentProgramId,
        installmentProgramMonthNo: this.getInstallmentProgram
          .installmentProgramMonthNo,
        installments: this.mainModel.installments,
        credits: this.mainModel.credits,
        transfer: this.mainModel.transfer,
        cash: this.mainModel.cash,
        refunds: this.mainModel.refunds,
        //tien mat
        cashAmount: this.calcPaymentsAmount(this.mainModel.cash),
        cashAccountId: this.mainModel.cash[0].paymentRefId,
        cashAccountCode: null,
        //chuyen khoan
        transferAmount: this.transferAmount,
        transferAccountID: this.mainModel.transfer[0].paymentRefId,
        transferAccountCode: this.mainModel.transfer[0].paymentCode,
        transferReferenceCode: this.mainModel.transfer[0].paymentCode,
        //quet the
        creditAmount: this.calcPaymentsAmount(this.mainModel.credits),
        creditAccountId: this.mainModel.credits[0].paymentRefId,
        creditAccountCode: this.mainModel.credits[0].paymentCode,
        creditCode: this.mainModel.credits[0].paymentCode,
        creditCardNo: this.mainModel.credits[0].creditCardNo,
        //phi quet the
        otherFees: this.mainModel.paymentInfo.creditCardFee
          ? unMaskPrice(this.mainModel.paymentInfo.creditCardFee)
          : 0,
        payCreditFeeType: this.mainModel.paymentInfo.payCreditFeeType,
        creditFeeAccountId: this.mainModel.paymentInfo.creditFeeAccountantId,
        creditFeeAcountCode: this.mainModel.paymentInfo.creditFeeAcountCode,
        //tra gop
        installedMoneyAmount: this.calcPaymentsAmount(
          this.mainModel.installments,
        ),
        installMoneyAccountId: this.mainModel.installments[0].paymentRefId,
        installMoneyAccountCode: this.mainModel.installments[0].paymentCode,
        customerIndentifyNo: this.mainModel.installments[0].customerIndentifyNo,
        installMoneyCode: this.mainModel.installments[0].installMoneyCode,
        isInterestZero: this.getInstallmentInterestZero,
        //thong tin hop dong
        contractCode: this.mainModel.whosesaleContractModel.contractCode,
        contractSigningDate: this.mainModel.whosesaleContractModel
          .contractSigningDate
          ? moment(
              this.mainModel.whosesaleContractModel.contractSigningDate,
              'DD/MM/YYYY',
            ).format('YYYY-MM-DD')
          : null,
      };
    },
    flattenFlexibleCombo(flexibleCombo) {
      return flexibleCombo.comboItems.map((comboItem) => {
        return {
          id: null,
          productId: comboItem.productId,
          productPrice: unMaskPrice(comboItem.sellingPrice),
          quantity: comboItem.quantity ? unMaskPrice(comboItem.quantity) : 1,
          discountAmount: comboItem.discountAmount
            ? unMaskPrice(comboItem.discountAmount)
            : 0,
          imeiCode: comboItem.imeiCode,
          productType: 1,
          accessoryGroupId: null,
          accessoryGroupCode: '',
          repurchasePrice: 0,
          isGiftTaken: true,
          note: '',
          gifts: [],
          attachs: [],
          discountType: AMOUNT_TYPE.MONEY,
          discountProgramId: null,
          issuedVat: true,
          flexibleComboId: flexibleCombo.flexibleComboId,
          flexibleComboItemId: comboItem.flexibleComboItemId,
        };
      });
    },
    calcPaymentsAmount(items) {
      return items.reduce((result, item) => {
        const unMask = unMaskPrice(item.paymentAmount) || 0;
        return result + Number(unMask);
      }, 0);
    },
    validatePayment(billData) {
      const payments = [
        billData.cash,
        billData.transfer,
        billData.credits,
        billData.installments,
        billData.refunds,
      ];
      const errorMessages = payments.flat().reduce((result, item) => {
        if (item && item.paymentAmount && !item.paymentRefId) {
          result.push(
            `Tài khoản thanh toán số tiền ${convertPrice(
              item.paymentAmount,
            )} không hợp lệ`,
          );
        }
        return result;
      }, []);
      return errorMessages;
    },
    formatListBillItem() {
      const mainItems = this.listBillItem.filter(
        (billItem) =>
          billItem.billItemType === BILL_ITEM_TYPE.PRODUCT &&
          !billItem.flexibleComboId,
      );
      const flexibleComboBillItems = this.listBillItem.filter(
        (billItem) =>
          billItem.billItemType === BILL_ITEM_TYPE.PRODUCT &&
          billItem.flexibleComboId,
      );
      const giftItems = this.listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS,
      );
      const attachItems = this.listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION,
      );

      const giftItemFormated = this.convertBillItem(giftItems);
      const attachItemFormated = this.convertBillItem(attachItems);

      const mainProductItems = mainItems.map((billItem) => {
        const gifts = giftItemFormated.filter(
          (giftItem) =>
            giftItem.belongBillDetailId === billItem.id && giftItem.checked,
        );
        const attachs = attachItemFormated.filter(
          (attchItem) => attchItem.belongBillDetailId === billItem.id,
        );

        return {
          id: billItem.id || null,
          productId: billItem.productId,
          productPrice: unMaskPrice(billItem.price),
          quantity: billItem.quantity ? unMaskPrice(billItem.quantity) : 1,
          discountAmount: billItem.discount
            ? unMaskPrice(billItem.discount)
            : 0,
          imeiCode: billItem.imeiCode,
          productType: billItem.billItemType,
          accessoryGroupId: billItem.accessoryGroupId,
          accessoryGroupCode: billItem.accessoryGroupCode,
          repurchasePrice: billItem.returnPrice
            ? unMaskPrice(billItem.returnPrice)
            : 0,
          isGiftTaken: billItem.checked,
          note: billItem.note,
          gifts: gifts,
          attachs: attachs,
          discountType: billItem.discountType,
          discountProgramId: billItem.discountProgramId,
          issuedVat: billItem.isGetVat,
          merchantId: billItem.merchantId || 1,
        };
      });
      let flexibleComboProductItems = [];
      flexibleComboBillItems.forEach((flexibleComboBillItem) => {
        const flexibleComboTransformed = this.flattenFlexibleCombo(
          flexibleComboBillItem,
        );
        flexibleComboProductItems = flexibleComboProductItems.concat(
          flexibleComboTransformed,
        );
      });
      return mainProductItems.concat(flexibleComboProductItems);
    },
    convertBillItem(items) {
      return items.map((billItem) => {
        return {
          id: billItem.id || null,
          productId: billItem.productId,
          productPrice: unMaskPrice(billItem.price),
          quantity: billItem.quantity ? unMaskPrice(billItem.quantity) : 1,
          discountAmount: billItem.discount
            ? unMaskPrice(billItem.discount)
            : 0,
          imeiCode: billItem.imeiCode,
          productType: billItem.billItemType,
          accessoryGroupId: billItem.accessoryGroupId,
          accessoryGroupCode: billItem.accessoryGroupCode,
          repurchasePrice: billItem.returnPrice
            ? unMaskPrice(billItem.returnPrice)
            : 0,
          isGiftTaken: billItem.checked,
          note: billItem.note,
          gifts: [],
          attachs: [],
          discountType: AMOUNT_TYPE.MONEY,
          discountProgramId: null,
          issuedVat: billItem.isGetVat,
          belongBillDetailId: billItem.belongBillDetailId,
          merchantId: billItem.merchantId || 1,
          checked: billItem.checked,
        };
      });
    },
    getListOrderSource() {
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listCustomerSource = data.data.data;
      });
    },
    getOrderById() {
      ApiService.query(`orders/${this.mainModel.orderId}`).then((data) => {
        const {
          saleNote,
          customerNote,
          customerId,
          discountAmount,
          discountType,
          transferAmount,
          depositAmount,
          storeId,
          orderItems,
          orderSourceId,
          storeName,
          creditAmount,
          installedMoneyAmount,
          customerShipFee,
          warrantyNote,
          codeShip,
          saleStoreId,
          saleStoreName,
        } = data.data.data;
        this.mainModel.billNote.warrantyNote = warrantyNote;
        this.mainModel.storeId = storeId;
        this.mainModel.storeName = storeName;
        this.mainModel.billEmployeeInfo.saleId = saleStoreId;
        this.mainModel.billEmployeeInfo.saleName = saleStoreName;
        this.mainModel.billNote.saleNote = saleNote;
        this.mainModel.billNote.customerNote = customerNote;
        this.mainModel.customerModel.customerId = customerId;
        this.mainModel.discountAmount = discountAmount ? discountAmount : 0;
        this.mainModel.discountType = discountType;
        this.searchStore = storeName;
        this.mainModel.orderSourceId = orderSourceId;
        this.mainModel.moneyTransfered = transferAmount;
        this.mainModel.moneyPaid = depositAmount;
        this.mainModel.moneyCredit = creditAmount;
        this.mainModel.moneyInstallment = installedMoneyAmount;
        this.mainModel.codeShip = codeShip;
        this.inputSearchProductProp.disabled = false;
        this.mainModel.customerModel.customerMobile =
          data.data.data.customerMobile;
        this.mainModel.customerModel.customerAddress =
          data.data.data.customerAddress;
        this.mainModel.customerModel.customerName = data.data.data.customerName;
        this.mainModel.customerModel.customerCity = data.data.data.customerCity;
        this.mainModel.customerModel.customerWard = data.data.data.customerWard;
        this.mainModel.customerModel.customerDistrict =
          data.data.data.customerDistrict;
        this.mainModel.vatInvoice.companyAddress =
          data.data.data.vatCompanyAddress;
        this.mainModel.vatInvoice.companyName = data.data.data.vatCompanyName;
        this.mainModel.vatInvoice.taxCode = data.data.data.taxCode;
        this.mainModel.vatInvoice.email = data.data.data.email;
        this.mainModel.couponCode = data.data.data.couponCode;
        this.mainModel.customerShipFee = customerShipFee ? customerShipFee : 0;
        this.mainModel.customerModel.totalPoint = data.data.data.customerPoint;
        this.mainModel.subtractPoint = data.data.data.subtractPoint;
        this.mainModel.totalAmountPoint = data.data.data.subtractPointAmount;
        this.mainModel.isSubtractPointValidated =
          data.data.data.subtractPointAmount > 0 ? true : false;
        this.listBillItem = orderItems.map((orderItem) => {
          let isGiftTaken = true;
          if (orderItem.isGiftTaken === 0) {
            isGiftTaken = true;
          }
          return {
            id: orderItem.id,
            productId: orderItem.productId,
            name: orderItem.productName,
            code: orderItem.productCode,
            productNameVat: orderItem.productNameVat,
            type: orderItem.productType,
            price: orderItem.productPrice,
            imeiCode: orderItem.imeiCode,
            quantity: orderItem.quantity,
            totalPrice: orderItem.totalAmount,
            discount:
              orderItem.orderItemType === AMOUNT_TYPE.MONEY
                ? orderItem.discountAmount
                : 0,
            discountType: AMOUNT_TYPE.MONEY,
            quantityInStock: orderItem.quantityInStock,
            comboItems: orderItem.productInCombos,
            storeId: storeId,
            deleted: orderItem.deleted,
            billItemType: orderItem.orderItemType,
            returnPrice: orderItem.repurchasePrice
              ? orderItem.repurchasePrice
              : 0,
            checked: isGiftTaken,
            orderItemType: orderItem.orderItemType,
            listAccessoryBonus: [],
            note: orderItem.note ? orderItem.note : '',
            listAccessoryPromotion: orderItem.listAccessoryPromotion
              ? orderItem.listAccessoryPromotion
              : [],
            belongBillDetailId: orderItem.belongOrderDetailId,
            isGetVat: false,
            merchantId: orderItem.merchantId,
          };
        });
        this.listBillItem = this.sortListBillItem(this.listBillItem);
        this.inputSearchProductProp.disabled = false;
        this.renderComponent = true;
      });
    },
    getBillById() {
      ApiService.get(`bills/${this.mainModel.billNumber}`).then(
        async (response) => {
          if (response.data.status === 0) {
            return makeToastFaile('Tải thông tin hóa đơn thất bại!');
          }
          const dataRes = response.data.data;
          const {
            id,
            storeId,
            storeName,
            siteCode,
            saleNote,
            customerNote,
            warrantyNote,
            customerId,
            discountAmount,
            discountType,
            orderSourceId,
            customerShipFee,
            codeShip,
            cashAmount,
            cashAccountId,
            cashAccName,
            cashAccountCode,
            transferAmount,
            transferAccountId,
            transferAccName,
            transferAccountCode,
            transferReferenceCode,
            creditAmount,
            creditAccountId,
            creditAccName,
            creditAccountCode,
            creditCardNo,
            creditCode,
            otherFees,
            payCreditFeeType,
            creditFeeAcountId,
            creditFeeAccName,
            creditFeeAcountCode,
            installedMoneyAmount,
            installMoneyAccountId,
            installmentAccName,
            customerIndentifyNo,
            installMoneyCode,
            depositAmount,
            transferFromOrder,
            moneyCredit,
            moneyInstallment,
            pointToMoney,
            subtractPoint,
            couponCode,
            billItems,
            editable,
            isSubtractPointValidated,
            isCountPoint,
            payments,
            createdAt,
          } = dataRes;
          this.mainModel.id = id;
          this.mainModel.storeId = storeId;
          this.mainModel.storeName = storeName;
          this.mainModel.siteCode = siteCode;
          this.mainModel.billNote.warrantyNote = warrantyNote;
          this.mainModel.billNote.saleNote = saleNote;
          this.mainModel.billNote.customerNote = customerNote;
          this.mainModel.customerModel.customerId = customerId;
          this.mainModel.customerModel.customerMobile = dataRes.customerPhone;
          this.mainModel.customerModel.customerAddress =
            dataRes.customerAddress;
          this.mainModel.customerModel.customerName = dataRes.customerName;
          this.mainModel.customerModel.customerCity = dataRes.customerCity;
          this.mainModel.customerModel.customerWard = dataRes.customerWard;
          this.mainModel.customerModel.customerDistrict =
            dataRes.customerDistrict;
          this.mainModel.customerModel.totalPoint = dataRes.customerPoint;
          this.mainModel.orderSourceId = orderSourceId;
          this.mainModel.discountAmount = discountAmount ? discountAmount : 0;
          this.mainModel.discountType = discountType;
          this.mainModel.codeShip = codeShip;
          this.mainModel.customerShipFee = customerShipFee
            ? customerShipFee
            : 0;
          this.mainModel.billEmployeeInfo.saleId = dataRes.saleId;
          this.mainModel.billEmployeeInfo.saleName = dataRes.saleName;
          this.mainModel.billEmployeeInfo.technicalId = dataRes.technicalId;
          this.mainModel.billEmployeeInfo.technicalName = dataRes.techName;
          this.mainModel.billEmployeeInfo.cashierId = dataRes.cashierId;
          this.mainModel.billEmployeeInfo.cashierName = dataRes.cashierName;
          this.mainModel.billEmployeeInfo.managerId = dataRes.managerId;
          this.mainModel.billEmployeeInfo.managerName = dataRes.managerName;
          this.mainModel.billEmployeeInfo.assistantId = dataRes.assistantId;
          this.mainModel.billEmployeeInfo.assistantName = dataRes.assistantName;
          this.mainModel.billEmployeeInfo.customerReceptionId =
            dataRes.customerReceptionId;
          this.mainModel.billEmployeeInfo.customerReceptionName =
            dataRes.customerReceptionName;
          this.mainModel.billEmployeeInfo.accessoryStaffId =
            dataRes.accessoryStaffId;
          this.mainModel.billEmployeeInfo.accessoryStaffName =
            dataRes.accessoryStaffName;
          this.mainModel.billEmployeeInfo.shipperId = dataRes.shipperId;
          this.mainModel.billEmployeeInfo.shipperName = dataRes.shipperName;
          this.mainModel.paymentInfo.cashAmount = cashAmount;
          this.mainModel.paymentInfo.cashAccountId = cashAccountId;
          this.mainModel.paymentInfo.cashAccountantName = cashAccName;
          this.mainModel.paymentInfo.cashAccountCode = cashAccountCode;
          this.mainModel.paymentInfo.transferAmount = transferAmount;
          this.mainModel.paymentInfo.transferAccountantId = transferAccountId;
          this.mainModel.paymentInfo.transferAccountantName = transferAccName;
          this.mainModel.paymentInfo.transferAccountCode = transferAccountCode;
          this.mainModel.paymentInfo.transferReferenceCode = transferReferenceCode;
          this.mainModel.paymentInfo.creditAmount = creditAmount;
          this.mainModel.paymentInfo.creditAccountantId = creditAccountId;
          this.mainModel.paymentInfo.creditAccountCode = creditAccountCode;
          this.mainModel.paymentInfo.creditAccountantName = creditAccName;
          this.mainModel.paymentInfo.creditCardNo = creditCode;
          this.mainModel.paymentInfo.creditCardCode = creditCardNo;
          this.mainModel.paymentInfo.creditCardFee = otherFees;
          this.mainModel.paymentInfo.payCreditFeeType = payCreditFeeType;
          this.mainModel.paymentInfo.creditFeeAccountantId = creditFeeAcountId;
          this.mainModel.paymentInfo.creditFeeAcountCode = creditFeeAcountCode;
          this.mainModel.paymentInfo.creditFeeAccountantName = creditFeeAccName;
          this.mainModel.paymentInfo.installedMoneyAmount = installedMoneyAmount;
          this.mainModel.paymentInfo.installMoneyAccountId = installMoneyAccountId;
          this.mainModel.paymentInfo.installmentAccountName = installmentAccName;
          this.mainModel.paymentInfo.customerIndentifyNo = customerIndentifyNo;
          this.mainModel.paymentInfo.installmentCode = installMoneyCode;
          this.mainModel.warrantyCustomerModel.customerId =
            dataRes.warrantyCustomerId;
          this.mainModel.warrantyCustomerModel.customerName =
            dataRes.warrantyCustomerName;
          this.mainModel.warrantyCustomerModel.customerMobile =
            dataRes.warrantyCustomerPhone;
          this.mainModel.warrantyCustomerModel.customerLastName =
            dataRes.warrantyCustomerLastName;
          this.mainModel.orderId = dataRes.orderId;
          this.mainModel.moneyTransfered = transferFromOrder;
          this.mainModel.moneyPaid = depositAmount;
          this.mainModel.moneyCredit = moneyCredit;
          this.mainModel.moneyInstallment = moneyInstallment;
          this.mainModel.totalAmountPoint = pointToMoney;
          this.mainModel.subtractPoint = subtractPoint;
          this.mainModel.isSubtractPointValidated = isSubtractPointValidated;
          this.mainModel.isCountPoint = isCountPoint;
          this.mainModel.couponCode = couponCode;
          this.mainModel.vatInvoice.companyName = dataRes.companyName;
          this.mainModel.vatInvoice.companyAddress = dataRes.vatCompanyAddress;
          this.mainModel.vatInvoice.vatCompanyType = dataRes.vatCompanyType;
          this.mainModel.vatInvoice.email = dataRes.email;
          this.mainModel.vatInvoice.taxCode = dataRes.taxCode;
          this.mainModel.companyBill = dataRes.taxCode ? true : false;
          this.mainModel.billNote.customerNote = dataRes.customerNote;
          this.mainModel.billNote.saleNote = dataRes.saleNote;
          this.mainModel.billNote.warrantyNote = dataRes.warrantyNote;
          this.mainModel.editable = editable;
          this.mainModel.billCreatedAt = createdAt;
          this.mainModel.whosesaleContractModel.contractCode =
            dataRes.contractCode;
          this.mainModel.whosesaleContractModel.contractSigningDate =
            dataRes.contractDate;
          this.mainModel.installments = payments.filter(
            (item) => item.paymentType === PAYMENT_TYPE.INSTALLMENT,
          );
          this.mainModel.credits = payments.filter(
            (item) => item.paymentType === PAYMENT_TYPE.CREDIT,
          );
          this.mainModel.transfer = payments.filter(
            (item) => item.paymentType === PAYMENT_TYPE.TRANSFER,
          );
          this.mainModel.cash = payments.filter(
            (item) => item.paymentType === PAYMENT_TYPE.CASH,
          );
          const flexibleComboBillItems = billItems.filter(
            (billItem) => billItem.flexibleComboId,
          );
          const normalBillItems = billItems.filter(
            (billItem) => !billItem.flexibleComboId,
          );
          const flexibleComboItems = this.combineFlexibleCombo(
            flexibleComboBillItems,
          );
          this.isInterestZero = dataRes.isInterestZero;
          this.$store.dispatch(SET_INTEREST, dataRes.isInterestZero);
          if (dataRes.installmentProgram) {
            await this.$nextTick();
            this.$store.dispatch(SET_INSTALLMENT_PROGRAM, {
              details: [],
              installmentProgramId: dataRes.installmentProgram.id,
              installmentProgramName: dataRes.installmentProgram.name,
              installmentProgramMonthNo: dataRes.installmentProgramMonthNo,
              monthNo: dataRes.installmentProgram.monthNo,
              prepayPercent: dataRes.installmentProgram.prepayPercent,
              conversionFeePercent:
                dataRes.installmentProgram.conversionFeePercent,
            });
          }
          this.listBillItem = normalBillItems.map((billItem) => {
            return {
              id: billItem.id,
              productId: billItem.productId,
              name: billItem.productName,
              productNameVat: billItem.productNameVat,
              code: billItem.productCode,
              price: billItem.productPrice,
              type: billItem.productType,
              quantity: billItem.quantity,
              imeiCode: billItem.imeiCode,
              totalPrice:
                billItem.quantity * billItem.productPrice -
                billItem.discountAmount,
              discount: billItem.discountAmount,
              comboItems: billItem.listProductInCombo,
              billItemType: billItem.billItemType,
              checked:
                billItem.isGiftTaken === null ? true : billItem.isGiftTaken,
              returnPrice: billItem.repurchasePrice
                ? billItem.repurchasePrice
                : 0,
              accessoryGroupId: billItem.accessoryGroupId
                ? billItem.accessoryGroupId
                : '',
              accessoryGroupCode: billItem.accessoryGroupCode
                ? billItem.accessoryGroupCode
                : '',
              note: billItem.note,
              belongBillDetailId: billItem.belongBillDetailId,
              listAccessoryPromotion: billItem.listProductPromotion,
              quantityInStock: billItem.quantityInStock,
              storeId: storeId,
              discountType: parseInt(billItem.discountType),
              discountProgramId: billItem.discountProgramId,
              isGetVat: billItem.issuedVat === 1 ? true : false,
              disabledByInstallmentProgram: this.applyInstallmentProgram
                ? true
                : false,
              isInstallmentProgramProduct: this.applyInstallmentProgram
                ? true
                : false,
              sellingPrice: billItem.sellingPrice || 0,
              listedPrice: billItem.listedPrice || 0,
              flexibleComboId: billItem.flexibleComboId || null,
              merchantId: billItem.merchantId || 1,
            };
          });
          this.listBillItem = this.listBillItem.concat(flexibleComboItems);
          this.listBillItem = this.sortListBillItem(this.listBillItem);
          this.inputSearchProductProp.disabled = false;
          if (this.$route.name === 'add-bill') {
            this.mainModel.id = null;
            this.mainModel.billNumber = null;
            this.mainModel.storeDisable = true;
          } else {
            this.pageTitle = `#${this.mainModel.billNumber} - Ngày: ${dataRes.createdAt}`;
          }
          setTimeout(() => {
            this.renderComponent = true;
          }, TIME_TRIGGER);

          this.listBillItem = billItems.map((billItem) => {
            return {
              id: billItem.id,
              productId: billItem.productId,
              name: billItem.productName,
              productNameVat: billItem.productNameVat,
              code: billItem.productCode,
              price: billItem.productPrice,
              type: billItem.productType,
              quantity: billItem.quantity,
              imeiCode: billItem.imeiCode,
              totalPrice:
                billItem.quantity * billItem.productPrice -
                billItem.discountAmount,
              discount: billItem.discountAmount,
              comboItems: billItem.listProductInCombo,
              billItemType: billItem.billItemType,
              checked:
                billItem.isGiftTaken === null ? true : billItem.isGiftTaken,
              returnPrice: billItem.repurchasePrice
                ? billItem.repurchasePrice
                : 0,
              accessoryGroupId: billItem.accessoryGroupId
                ? billItem.accessoryGroupId
                : '',
              accessoryGroupCode: billItem.accessoryGroupCode
                ? billItem.accessoryGroupCode
                : '',
              note: billItem.note,
              belongBillDetailId: billItem.belongBillDetailId,
              listAccessoryPromotion: billItem.listProductPromotion,
              quantityInStock: billItem.quantityInStock,
              storeId: storeId,
              discountType: billItem.discountType,
              discountProgramId: billItem.discountProgramId,
              isGetVat: billItem.issuedVat === 1 ? true : false,
              disabledByInstallmentProgram: this.applyInstallmentProgram
                ? true
                : false,
              isInstallmentProgramProduct: this.applyInstallmentProgram
                ? true
                : false,
              sellingPrice: billItem.sellingPrice || 0,
              listedPrice: billItem.listedPrice || 0,
              merchantId: billItem.merchantId || 1,
            };
          });
          this.listBillItem = this.sortListBillItem(this.listBillItem);
          this.inputSearchProductProp.disabled = false;
          if (this.$route.name === 'add-bill') {
            this.mainModel.id = null;
            this.mainModel.billNumber = null;
            this.mainModel.storeDisable = true;
          } else {
            this.pageTitle = `#${this.mainModel.billNumber} - Ngày: ${dataRes.createdAt}`;
          }
          setTimeout(() => {
            this.renderComponent = true;
          }, TIME_TRIGGER);

          this.fetchStoreByUser();
        },
      );
    },
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    },
    viewHistoryDetail(item) {
      this.$router.push({
        name: 'detail-bill-history',
        query: { id: item.id },
      });
    },
    fetchBillHistory: async function() {
      const params = {
        //  loại hóa đơn
        type: 2,
      };
      await ApiService.query(
        '/histories/getByReferenceId/' + this.mainModel.billNumber,
        {
          params,
        },
      ).then((res) => {
        res.data.data.map((x) => {
          let data = {
            id: x.id,
            type: x.type,
            referenceId: x.referenceId,
            action: x.action,
            description: x.description,
            originalValue: x.originalValue,
            newValue: x.newValue,
            updatedBy: x.updatedBy,
            createdAt: moment(String(x.createdAt)).format('DD/MM/YYYY HH:mm'),
            updatedAt: moment(x.updatedAt).format('DD/MM HH:mm'),
          };
          this.histories.push(data);
        });
      });
    },
    onUpdatePayment(paymentInfo) {
      this.mainModel.paymentInfo = cloneDeep(paymentInfo);
    },
    onUpdatePaymentV2(items = [], type) {
      const payments = items.reduce((result, current) => {
        result.push({
          ...current,
          paymentAmount: unMaskPrice(current.paymentAmount),
        });
        return result;
      }, []);
      switch (type) {
        case PAYMENT_TYPE.INSTALLMENT: {
          this.mainModel.installments = payments;
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          this.mainModel.credits = payments;
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          this.mainModel.transfer = payments;
          break;
        }
        case PAYMENT_TYPE.CASH: {
          this.mainModel.cash = payments;
          break;
        }
        case PAYMENT_TYPE.REFUND: {
          this.mainModel.refunds = payments;
          break;
        }
      }
    },
    onUpdateCustomer(customerInfo) {
      this.mainModel.customerModel = cloneDeep(customerInfo);
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.inputSearchProductProp.disabled = false;
          if (!this.mainModel.billEmployeeInfo.saleId) {
            this.mainModel.billEmployeeInfo.saleId = data.data.id;
            this.mainModel.billEmployeeInfo.saleName = data.data.employeeName;
          }

          if (
            data.data.jobTitleId === 6 &&
            !this.mainModel.billEmployeeInfo.cashierId
          ) {
            this.mainModel.billEmployeeInfo.cashierId = data.data.id;
            this.mainModel.billEmployeeInfo.cashierName =
              data.data.employeeName;
          }
          const stores = data.data.stores;
          if (stores.length === 1 && !this.mainModel.storeId) {
            this.mainModel.storeId = stores[0].id;
            this.mainModel.storeName = stores[0].name;
            this.mainModel.storeDisable = true;
            this.fetchShiftWorkConfig();
          }
          if (this.mainModel.id) {
            this.fetchShiftWorkConfig();
            this.mainModel.storeDisable = true;
          }
        }
      });
    },
    fetchCustomerCransaction(customerTransaction) {
      this.orderNotComplete = customerTransaction.orderNotComplete;
    },
    showWarningOrderNotComplete: function(billData) {
      Swal.fire({
        title: 'Cảnh báo đơn hàng!',
        text:
          'Khách hàng có đơn hàng chưa hoàn thành, bạn có chắc muốn tạo hóa đơn mới cho khách hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Tạo mới',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.onCallApiCreate(billData);
        } else {
          this.onSubmiting = false;
        }
      });
    },
    linkToWarranty(id) {
      this.$router.push({
        name: 'add-import-warranty',
        query: { refId: id },
      });
    },
    showActiveModal(imeiCode) {
      this.$refs['active-imei-modal'].showModal(
        imeiCode,
        this.mainModel.customerModel.customerMobile,
        this.mainModel.storeName,
        this.mainModel.siteCode,
      );
    },
    fetchProgramByProductIds(isInterestZero) {
      const items = cloneDeep(this.listBillItem);
      const productIds = items.map((item) => item.productId);
      if (!productIds.length) {
        return;
      }
      const params = {
        productIds: productIds,
        isInterestZero: isInterestZero,
      };
      ApiService.query('installment-programs/product-ids', {
        params: params,
      }).then((response) => {
        const { data } = response.data;
        let programs = [];
        items.map((item, index) => {
          const productFinded = data.find(
            (product) => product.productId === item.productId,
          );
          if (productFinded) {
            item.sellingPrice = productFinded.programPrice;
            item.price = productFinded.programPrice;
            programs = programs.concat(productFinded.programs);
            this.$set(this.listBillItem, index, item);
          }
        });
        this.selectInstallmentProgram(isInterestZero, programs);
      });
    },
    selectInstallmentProgram(isInterestZero, programs) {
      if (isInterestZero) {
        const firstChoiceProram = programs[0];
        if (firstChoiceProram) {
          this.$store.dispatch(SET_INSTALLMENT_PROGRAM, {
            installmentProgramId: firstChoiceProram.id,
            installmentProgramName: firstChoiceProram.name,
            monthNo: firstChoiceProram.monthNo,
            installmentProgramMonthNo: null,
            prepayPercent: firstChoiceProram.prepayPercent,
            conversionFeePercent: firstChoiceProram.conversionFeePercent,
            details: programs,
          });
        }
      } else {
        this.$store.dispatch(PURGE_INSTALLMENT_PROGRAM);
      }
    },
    onShowInterestProgram() {
      this.$refs['bill-payment'].onLoading.interestZero = true;
      this.$nextTick(() => {
        EventBus.$emit('popup-insterest-program');
      });
    },
    searchFlexibleCombo(textSearch) {
      const params = {
        textSearch: textSearch,
        storeId: this.mainModel.storeId,
      };
      ApiService.query('flexible-combo/search', { params }).then(({ data }) => {
        const products = data.data;
        this.optionsProduct = products.map((product) => {
          return {
            productId: product.id,
            productName: product.name,
            productCode: product.code,
            productNameVat: '',
            discountAmount: product.discountAmount,
            discountType: product.discountType,
            discountTypeName: product.discountTypeName,
            productType: 3,
            imeiCode: '',
            listAccessoryBonus: [],
            listAccessoryPromotion: [],
            quantityInStock: product.inStockQuantity
              ? product.inStockQuantity
              : 0,
            listedPrice: 0,
            listItem: product.listItem,
          };
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct,
          },
        ];
        this.isSearching = false;
        this.productLoading = false;
      });
    },
    showModalFlexibleCombo() {
      setTimeout(() => {
        this.$refs['flexible-combo'].showModal();
      }, TIME_TRIGGER);
    },
    addComboChild(billItem) {
      billItem.id = v4();
      this.listBillItem.push(billItem);
      this.sortListBillItem(this.listBillItem);
    },
    combineFlexibleCombo(billItems) {
      const flexibleComboIds = uniq(
        billItems.map(
          (flexibleComboBillItem) => flexibleComboBillItem.flexibleComboId,
        ),
      );
      return flexibleComboIds.map((flexibleComboId) => {
        const flexibleComboItems = billItems.filter(
          (billItem) => billItem.flexibleComboId === flexibleComboId,
        );
        let comboName = '';
        if (flexibleComboItems.length) {
          comboName = flexibleComboItems[0].flexibleComboName;
        }
        const comboItems = flexibleComboItems.map((flexibleComboItem) => {
          return {
            id: flexibleComboItem.id,
            productCode: flexibleComboItem.productCode
              ? flexibleComboItem.productCode
              : '',
            productName: flexibleComboItem.productName
              ? flexibleComboItem.productName
              : '',
            quantity: flexibleComboItem.quantity,
            productId: flexibleComboItem.productId,
            productPrice: flexibleComboItem.productPrice
              ? flexibleComboItem.productPrice
              : 0,
            sellingPrice: flexibleComboItem.productPrice
              ? flexibleComboItem.productPrice
              : 0,
            productType: PRODUCT_TYPE.PRODUCT,
            discountAmount: flexibleComboItem.discountAmount,
            productComboPrice: 0,
            productComboRate: 0,
            productVatAmount: flexibleComboItem.productVatAmount,
            productNameVat: flexibleComboItem.productNameVat
              ? flexibleComboItem.productNameVat
              : '',
            listedPrice: flexibleComboItem.productPrice
              ? flexibleComboItem.productPrice
              : 0,
          };
        });
        const flexibleComboPrice = comboItems.reduce((result, product) => {
          result +=
            product.productPrice * product.quantity - product.discountAmount;
          return result;
        }, 0);
        return {
          id: flexibleComboId,
          productId: flexibleComboId,
          name: comboName,
          code: '',
          productNameVat: '',
          productNote: '',
          productType: PRODUCT_TYPE.PRODUCT_COMBO,
          quantity: 1,
          productPrice: flexibleComboPrice,
          imeiCode: '',
          totalPrice: flexibleComboPrice,
          discount: 0,
          discountType: AMOUNT_TYPE.PERCENT,
          comboItems: comboItems,
          billItemType: BILL_ITEM_TYPE.PRODUCT,
          checked: true,
          accessoryGroupId: null,
          accessoryGroupCode: '',
          repurchasePrice: 0,
          belongBillDetailId: null,
          note: '',
          listAccessoryPromotion: [],
          listProductPromotion: [],
          issuedVat: true,
          sellingPrice: flexibleComboPrice,
          price: flexibleComboPrice,
          listedPrice: flexibleComboPrice,
          flexibleComboId: flexibleComboId,
        };
      });
    },
    showModalImportProduct() {
      if (!this.mainModel.storeId) {
        return makeToastFaile('Vui lòng chọn cửa hàng!');
      }
      this.$refs['product-sale-excel'].showModal();
    },
    validDataImport(productItems) {
      this.listBillItem = this.listBillItem.concat(productItems);
      this.proSTT = 0;
      this.listBillItem.forEach((element, index) => {
        if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) {
          this.proSTT++;
          this.listBillItem[index].stt = this.proSTT;
        }
      });
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  font-weight: 500;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 5px 8px 5px 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>

<style lang="scss">
.whosesale-bill-info {
  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    flex: 0 0 calc(15% - 0.25rem);
  }

  @media (max-width: 1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 calc(17% - 0.25rem);
    }
  }

  .wizard-nav-bg {
    background-color: #f7f7f7;
    border-radius: 5px 5px 0px 0px;
  }

  .alert-header {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .box {
    background: black;
    border-radius: 4px;
    padding-bottom: 100%;
  }
  .headerTable {
    color: rgb(24, 28, 50);
    text-align: center;
  }

  .form-group label {
    font-weight: 500;
  }

  .textExcessCash {
    font-weight: 500;
    color: rgba(71, 71, 255, 0.87);
  }
  .row-center-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textPoint {
    font-size: 14px;
    font-weight: 500;
  }
  .textFinal {
    font-weight: 500;
    color: brown;
  }
  .textTitlePayment {
    font-weight: 500;
    font-size: 14px;
  }

  .textPayment {
    font-size: 16px;
    font-weight: 500;
    color: brown;
  }

  .no-side {
    padding-left: 0px;
    padding-right: 0px;
  }
  .dropdown-custom {
    .btn-sm,
    .btn-group-sm > .btn {
      padding: 1px 2px;
      background-color: transparent;
      border: none;
      border-radius: 3px;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>

<style lang="scss">
.upload-file {
  table {
    table-layout: fixed;
  }
  .dropbox {
    margin: auto;
    width: 70%;
    background: #f8f8f8;
    border-radius: 20px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    left: 0px;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #e8f5e9;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  .img-container {
    position: relative;
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
  }
  .preview-img {
    max-width: 80px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #eeecff;
    border-radius: 5px;
  }
  .preview-img-container {
    border: 2px dashed #eeecff;
    padding: 2rem 1rem;
  }

  .preview-box {
    display: inline-block;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
  }
  .remove {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
  }
  .cancel {
    color: #545454;
    text-decoration: none;
  }
}
</style>
