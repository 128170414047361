<template>
  <div class="bill-employee">
    <b-form-group :disabled="disabled">
      <label for="input-name">Nhân viên bán hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Sale')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.saleName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Nhân viên bán hàng'"
        :limit="10"
        @select="onSelected($event, 'Sale')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.TVBH)">
        <template #custom="{suggestion}">
        <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Nhân viên kỹ thuật:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Technical')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.technicalName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Nhân viên bán hàng'"
        :limit="10"
        @select="onSelected($event, 'Technical')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.KTPM)">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <!-- <b-form-group :disabled="disabled">
      <label>Nhân viên CDPK:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Accessory')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.accessoryStaffName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Nhân viên CDPK'"
        :limit="10"
        @select="onSelected($event, 'Accessory')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.CDPK)">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group> -->
    <b-form-group :disabled="disabled">
      <label>Nhân viên thu ngân:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Cashier')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.cashierName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Nhân viên thu ngân'"
        :limit="10"
        @select="onSelected($event, 'Cashier')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.KTBH)">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Quản lý cửa hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Manager')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.managerName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Quản lý cửa hàng'"
        :limit="10"
        @select="onSelected($event, 'Manager')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.QL)">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Trợ lý cửa hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Assistant')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.assistantName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Trợ lý cửa hàng'"
        :limit="10"
        @select="onSelected($event, 'Assistant')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.TL)">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <b-form-group>
      <label>Tiếp đón khách hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('CustomerReception')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.customerReceptionName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Tiếp đón khách hàng'"
        :limit="10"
        @select="onSelected($event, 'CustomerReception')"
        @change="onInputChange"
        @focus="onFocusInput(jobTitle.TDKH)">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
    <b-form-group>
      <label>Nhân viên giao hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Shipper')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <Autosuggest
        :disabled="disabled"
        :model.sync="billEmployeeInfo.shipperName"
        :suggestions="filteredEmployeeOptions"
        :placeholder="'Nhân viên giao hàng'"
        :limit="10"
        @select="onSelected($event, 'Shipper')"
        @change="onInputChange">
        <template #custom="{suggestion}">
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </template>
      </Autosuggest>
    </b-form-group>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { JOB_TITLE } from '../../../utils/enum';
import { xoa_dau } from '../../../utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  props: ['storeId', 'billDataEmployeeInfo', 'disabled'],
  components: { Autosuggest },
  data() {
    return {
      filteredOptionsSale: [],
      filteredEmployeeOptions: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      jobTitle: JOB_TITLE,
      billEmployeeInfo: {
        saleId: null,
        saleName: '',
        technicalId: null,
        technicalName: '',
        cashierId: null,
        cashierName: '',
        accessoryStaffId: null,
        accessoryStaffName: '',
        managerId: null,
        managerName: '',
        assistantId: null,
        assistantName: '',
        customerReceptionId: null,
        customerReceptionName: '',
        shipperId: null,
        shipperName: '',
      },
      billDataEmployee: null,
    };
  },
  computed: {},
  beforeUpdate() {
    this.billEmployeeInfo = this.billDataEmployeeInfo;
    this.billDataEmployee = this.billDataEmployeeInfo;
  },
  mounted() {
    this.billEmployeeInfo = this.billDataEmployeeInfo;
    this.billDataEmployee = this.billDataEmployeeInfo;
    if (this.storeId) {
      this.fetchEmployeeByStore();
    }
  },
  watch: {
    storeId: {
      handler: function (newValue, oldValue) {
        let isChangeStore = false;
        if (oldValue && oldValue !== newValue) {
          isChangeStore = true;
        }
        this.fetchEmployeeByStore(isChangeStore);
      },
    },
  },
  methods: {
    onSelected(option, type) {
      switch (type) {
        case 'Sale':
          this.billEmployeeInfo.saleId = option.item.id;
          this.billEmployeeInfo.saleName = option.item.fullName;
          this.billDataEmployee.saleId = option.item.id;
          break;
        case 'Technical':
          this.billEmployeeInfo.technicalId = option.item.id;
          this.billEmployeeInfo.technicalName = option.item.fullName;
          this.billDataEmployee.technicalId = option.item.id;
          break;
        case 'Accessory':
          this.billEmployeeInfo.accessoryStaffId = option.item.id;
          this.billEmployeeInfo.accessoryStaffName = option.item.fullName;
          this.billDataEmployee.accessoryStaffId = option.item.id;
          break;
        case 'Cashier':
          this.billEmployeeInfo.cashierId = option.item.id;
          this.billEmployeeInfo.cashierName = option.item.fullName;
          this.billDataEmployee.cashierId = option.item.id;
          break;
        case 'Manager':
          this.billEmployeeInfo.managerId = option.item.id;
          this.billEmployeeInfo.managerName = option.item.fullName;
          this.billDataEmployee.managerId = option.item.id;
          break;
        case 'Assistant':
          this.billEmployeeInfo.assistantId = option.item.id;
          this.billEmployeeInfo.assistantName = option.item.fullName;
          this.billDataEmployee.assistantId = option.item.id;
          break;
        case 'CustomerReception':
          this.billEmployeeInfo.customerReceptionId = option.item.id;
          this.billEmployeeInfo.customerReceptionName = option.item.fullName;
          this.billDataEmployee.customerReceptionId = option.item.id;
          break;
        case 'Shipper':
          this.billEmployeeInfo.shipperId = option.item.id;
          this.billEmployeeInfo.shipperName = option.item.fullName;
          this.billDataEmployee.shipperId = option.item.id;
          break;
      }
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }

      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.fullName);
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredEmployeeOptions = filteredData;
    },
    fetchEmployeeByStore(isChangeStore) {
      // const listStoreId = [this.storeId];
      if (isChangeStore) {
        this.billDataEmployee = {};
        this.billEmployeeInfo = {};
      }
      ApiService.get(
        // `employees/get-by-store?employeeName=&storeId=${listStoreId}`
        `users/get-by-store?storeId=${this.storeId}`
      ).then((data) => {
        const employees = data.data.data;
        this.optionsEmployee[0].data = employees.map((item) => {
          return {
            id: item.id,
            code: item.code ? item.code : '',
            fullName: item.fullName ? item.fullName : '',
            jobTitleId: item.jobTitleId,
          };
        });
        this.filteredEmployeeOptions = [...this.optionsEmployee[0].data];
      });
    },
    onFocusInput(jobTitleId) {
      const suggesstionData = this.sortEmpByJobTitle(jobTitleId);
      this.filteredEmployeeOptions = [...suggesstionData];
    },
    sortEmpByJobTitle(jobTitleId) {
      const empJobs = this.optionsEmployee[0].data.filter(
        (emp) => emp.jobTitleId === jobTitleId
      );
      const empDiffJobs = this.optionsEmployee[0].data.filter(
        (emp) => emp.jobTitleId !== jobTitleId
      );
      return empJobs.concat(empDiffJobs);
    },
    showWarning(type) {
      if (!this.billDataEmployee) {
        return false;
      }
      switch (type) {
        case 'Sale':
          if (!this.billDataEmployee.saleId) {
            return true;
          }

          return false;
        case 'Technical':
          if (!this.billDataEmployee.technicalId) {
            return true;
          }

          return false;
        case 'Accessory':
          if (!this.billDataEmployee.accessoryStaffId) {
            return true;
          }

          return false;
        case 'Cashier':
          if (!this.billDataEmployee.cashierId) {
            return true;
          }

          return false;
        case 'Manager':
          if (!this.billDataEmployee.managerId) {
            return true;
          }

          return false;
        case 'Assistant':
          if (!this.billDataEmployee.assistantId) {
            return true;
          }

          return false;
        case 'CustomerReception':
          if (!this.billDataEmployee.customerReceptionId) {
            return true;
          }

          return false;
        case 'Shipper':
          if (!this.billDataEmployee.shipperId) {
            return true;
          }

          return false;
        default:
          return false;
      }
    },
  },
};
</script>


<style lang="scss">
.bill-employee {
  .icon-size {
    font-size: 1rem !important;
  }
}
</style>
