<template>
  <div class="voucher-tag">
    <span class="voucher-tag-wrap"
      ><span>{{ voucherCode }}</span>
      <span class="tag-remove-icon" @click="onRemoveTag">
        <i class="fas fa-times"></i>
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: ['voucherCode'],
  data() {
    return {};
  },
  methods: {
    onRemoveTag() {
      this.$emit('removeTag');
    },
  },
};
</script>
<style>
.voucher-tag {
  display: inline-block;
  background-color: rgb(53, 149, 246);
  border-radius: 5px;
  padding: 2px 4px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 1.5;
  font-weight: 600;
}
.voucher-tag-wrap {
  display: inline-block;
  margin-right: 5px;
}
.tag-remove-icon {
  display: inline-block;
  cursor: pointer;
  margin-left: 7px;
}

.tag-remove-icon i {
  font-size: 14px;
  color: white;
  transition: color 0.2s ease-in-out;
}

.tag-remove-icon i:hover {
  color: #333;
}
</style>